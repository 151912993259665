import YesNoSecurity from "@/enumerations/Security/YesNoSecurity";
import SecuritySettingsBase from "./SecuritySettingsBase";

export default class CommoditySecuritySettings extends SecuritySettingsBase {
  private static instance: CommoditySecuritySettings;

  private constructor() {
    super();
  }

  public static getInstance(): CommoditySecuritySettings {
    if (!CommoditySecuritySettings.instance) {
      CommoditySecuritySettings.instance = new CommoditySecuritySettings();
    }

    return CommoditySecuritySettings.instance;
  }

  private get showCommodity(): boolean {
    return (
      !this.isManagerAccountSelected &&
      this.checkUserProfileBasedSecurity(
        this.userProfileBasedSecurity.commodity,
        YesNoSecurity.yes
      )
    );
  }

  public get showCommodityMenu(): boolean {
    return (
      this.parameters.showCgMenu &&
      this.showCommodity &&
      (this.showCashBidsPage ||
        this.showBalancesPage ||
        this.showActivityPage ||
        this.showContractsPage ||
        this.showTicketsPage ||
        this.showGrainBankPage ||
        this.showProofOfYieldPage)
    );
  }

  public get showCashBidsPage() {
    return (
      this.parameters.showCgBids &&
      this.showCommodity &&
      this.checkUserProfileBasedSecurity(
        this.userProfileBasedSecurity.viewCashBids,
        YesNoSecurity.yes
      )
    );
  }

  public get showBalancesPage() {
    return (
      this.parameters.showCgBalances &&
      this.showCommodity &&
      this.checkUserProfileBasedSecurity(
        this.userProfileBasedSecurity.viewCommodityBalances,
        YesNoSecurity.yes
      )
    );
  }

  public get showActivityPage() {
    return (
      this.parameters.showCgActivity &&
      this.showCommodity &&
      this.checkUserProfileBasedSecurity(
        this.userProfileBasedSecurity.viewCommodityActivity,
        YesNoSecurity.yes
      )
    );
  }

  public get showContractsPage() {
    return (
      this.parameters.showCgContracts &&
      this.showCommodity &&
      this.checkUserProfileBasedSecurity(
        this.userProfileBasedSecurity.viewCommodityContracts,
        YesNoSecurity.yes
      )
    );
  }

  public get showTicketsPage() {
    return (
      this.parameters.showCgTickets &&
      this.showCommodity &&
      this.checkUserProfileBasedSecurity(
        this.userProfileBasedSecurity.viewCommodityTickets,
        YesNoSecurity.yes
      )
    );
  }

  public get showGrainBankPage() {
    return (
      this.parameters.showCgGrainBank &&
      this.showCommodity &&
      this.checkUserProfileBasedSecurity(
        this.userProfileBasedSecurity.viewCommodityGrainBank,
        YesNoSecurity.yes
      )
    );
  }

  public get showProofOfYieldPage() {
    return (
      this.parameters.showCgProofOfYield &&
      this.showCommodity &&
      this.checkUserProfileBasedSecurity(
        this.userProfileBasedSecurity.viewCommodityProofOfYield,
        YesNoSecurity.yes
      )
    );
  }
}

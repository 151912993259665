import Vue from "vue";
import Vuetify, {
  VSimpleTable,
  VSelect,
  VChip,
  VSimpleCheckbox,
  VSubheader,
  VSwitch,
} from "vuetify/lib";
import { Touch } from "vuetify/lib/directives";
import { VuetifyThemeVariant } from "vuetify/types/services/theme";

Vue.use(Vuetify, { directives: { Touch } });

export default function UseVuetify(variablesObject: VuetifyThemeVariant) {
  const instance = new Vuetify({
    icons: {
      iconfont: "mdi",
    },
    theme: {
      dark: false,
      options: {
        customProperties: true,
      },
      themes: {
        light: {
          primary: "#1867c0",
          secondary: "#999",
          accent: "#ecaf3d",
          error: "#f44336",
          info: "#2196f3",
          success: "#00c853",
        },
      },
    },
    components: {
      //add vuetify components here when getting mini-css-extract-plugin warnings regarding conflicting order while doing a production build
      //https://github.com/facebook/create-react-app/issues/5372#issuecomment-636769746
      VSimpleTable,
      VSelect,
      VChip,
      VSimpleCheckbox,
      VSubheader,
      VSwitch,
    },
  });

  if (variablesObject) {
    for (const [key, value] of Object.entries(variablesObject)) {
      //if client variable exists, override Vuetify variable. Otherwise keep original variable
      if (value) {
        const vuetifyVariable = key.toLowerCase();
        instance.framework.theme.themes.light[vuetifyVariable] = value;
      }
    }
  }

  return instance;
}

import Vue from "vue";
import {
  formatCurrency,
  formatNumber,
  formatNumberBlankWithNull,
  formatPercentage,
  formatPositiveNegativeNumber,
  mask,
} from "@/helpers/Numeric/NumericFormattingHelpers";

Vue.filter("formatCurrency", function (value: string | number): string {
  return formatCurrency(value);
});

Vue.filter(
  "formatPercentage",
  function (value: string | number, decimalPlaces?: number): string {
    return formatPercentage(value, decimalPlaces);
  }
);

Vue.filter(
  "formatPositiveNegativeNumber",
  function (value: string | number): string {
    return formatPositiveNegativeNumber(value);
  }
);

Vue.filter(
  "formatNumber",
  (value: string | number, decimalPlaces?: number): string => {
    return formatNumber(value, decimalPlaces);
  }
);

Vue.filter(
  "formatNumberBlankWithNull",
  (value: string | number, decimalPlaces?: number): string => {
    return formatNumberBlankWithNull(value, decimalPlaces);
  }
);

Vue.filter("mask", (value: string, numberOfPlacesOmitted?: number): string => {
  return mask(value, numberOfPlacesOmitted);
});

const ViewSalesOverview = () =>
  import(
    /* webpackChunkName: "ManagerViewSalesOverview" */ "@/views/Manager/ViewSalesOverview.vue"
  );
import { RouteConfig } from "vue-router";
import GeneralSecuritySettings from "@/helpers/Security/GeneralSecuritySettings";

const securitySettings = GeneralSecuritySettings.getInstance();

const managerMenuRoutes: RouteConfig[] = [
  {
    path: "/manager/salesOverview",
    name: "view-sales-overview",
    component: ViewSalesOverview,
    meta: {
      displayName: "Sales Overview",
    },
    beforeEnter: (to, from, next) =>
      securitySettings.managerAccountSelected ? next() : next("/"),
  },
];

export default managerMenuRoutes;

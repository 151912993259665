import WebLogin from "@/interfaces/WebLogin/WebLogin";

export function getAccountNameInitials(fullName: string): string {
  if (!fullName) return "";

  const namePartArray = fullName.split(" ");

  let initials = "";
  for (const namePart of namePartArray) {
    if (initials.length < 2) {
      if (/^[a-zA-Z]+$/.test(namePart[0])) {
        initials += namePart[0];
      }
    }
  }

  return initials.toUpperCase();
}

export function getWebLoginFromJsonWebToken(jwt: string): WebLogin {
  const jwtParsed = JSON.parse(atob(jwt.split(".")[1]));

  return {
    webLoginId: jwtParsed.webLoginId,
    keepLoggedIn: jwtParsed.keepLoggedIn === "True",
    isEmployee: jwtParsed.isEmployee === "True",
    employeeSecurity: parseInt(jwtParsed.employeeSecurity),
    employeeCustomerAccess: parseInt(jwtParsed.employeeCustomerAccess),
    employeeManagerName: jwtParsed.employeeManagerName,
    forcePasswordChange: jwtParsed.forcePasswordChange === "True",
  } as WebLogin;
}

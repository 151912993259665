
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class LandingBaseView extends Vue {
  @Prop({ default: 400 })
  maxWidth!: number;

  @Prop({ default: true })
  showDivider!: boolean;

  store = this.$store.direct;
  isInstalledAsPwa = false;

  created() {
    this.isInstalledAsPwa = window.matchMedia(
      "(display-mode: standalone)"
    ).matches;
  }
}

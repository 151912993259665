import { Vue, Component } from "vue-property-decorator";
import CommoditySecuritySettings from "@/helpers/Security/CommoditySecuritySettings";
import GeneralSecuritySettings from "@/helpers/Security/GeneralSecuritySettings";
import PaymentsSecuritySettings from "@/helpers/Security/PaymentsSecuritySettings";
import AccountSecuritySettings from "@/helpers/Security/AccountSecuritySettings";
import OrdersSecuritySettings from "@/helpers/Security/OrdersSecuritySettings";
import ContractsSecuritySettings from "@/helpers/Security/ContractsSecuritySettings";
import PurchasesSecuritySettings from "@/helpers/Security/PurchasesSecuritySettings";
@Component
export default class SecuritySettingsMixin extends Vue {
  public get generalSecuritySettings(): GeneralSecuritySettings {
    return GeneralSecuritySettings.getInstance();
  }

  public get accountSecuritySettings(): AccountSecuritySettings {
    return AccountSecuritySettings.getInstance();
  }

  public get ordersSecuritySettings(): OrdersSecuritySettings {
    return OrdersSecuritySettings.getInstance();
  }

  public get purchasesSecuritySettings(): PurchasesSecuritySettings {
    return PurchasesSecuritySettings.getInstance();
  }

  public get contractsSecuritySettings(): ContractsSecuritySettings {
    return ContractsSecuritySettings.getInstance();
  }

  public get commoditySecuritySettings(): CommoditySecuritySettings {
    return CommoditySecuritySettings.getInstance();
  }

  public get paymentsSecuritySettings(): PaymentsSecuritySettings {
    return PaymentsSecuritySettings.getInstance();
  }
}

import ViewOnlyFullAccessSecurity from "@/enumerations/Security/ViewOnlyFullAccessSecurity";
import YesNoSecurity from "@/enumerations/Security/YesNoSecurity";
import SecuritySettingsBase from "./SecuritySettingsBase";
import EditAutoPaymentsEnum from "@/enumerations/Payments/EditAutoPayments";

export default class PaymentsSecuritySettings extends SecuritySettingsBase {
  private static instance: PaymentsSecuritySettings;

  private constructor() {
    super();
  }

  public static getInstance(): PaymentsSecuritySettings {
    if (!PaymentsSecuritySettings.instance) {
      PaymentsSecuritySettings.instance = new PaymentsSecuritySettings();
    }

    return PaymentsSecuritySettings.instance;
  }

  private get showPayments(): boolean {
    return (
      !this.isManagerAccountSelected &&
      (this.useEntities
        ? this.entityParameters.showPaymentsMenu
        : this.parameters.showPaymentsMenu) &&
      this.checkUserProfileBasedSecurity(
        this.userProfileBasedSecurity.payments,
        YesNoSecurity.yes
      )
    );
  }

  public get showPaymentsMenu(): boolean {
    return (
      this.showPayments &&
      (this.showPaymentHistoryPage ||
        this.showAutoPaymentsPage ||
        this.showCreatePaymentPage ||
        this.showPendingPaymentsPage ||
        this.showPaymentSourcesPage)
    );
  }

  public get showCreatePaymentPage(): boolean {
    return (
      this.showPayments &&
      this.checkUserProfileBasedSecurity(
        this.userProfileBasedSecurity.createPayment,
        YesNoSecurity.yes
      )
    );
  }

  public get showPaymentHistoryPage(): boolean {
    return (
      this.showPayments &&
      (this.useEntities
        ? this.entityParameters.showPaymentsHistory
        : this.parameters.showPaymentsHistory) &&
      this.checkUserProfileBasedSecurity(
        this.userProfileBasedSecurity.viewPaymentHistory,
        YesNoSecurity.yes
      )
    );
  }

  public get showAutoPaymentsPage(): boolean {
    return (
      this.showPayments &&
      (this.useEntities
        ? this.entityParameters.editAutoPayments
        : this.parameters.editAutoPayments) !=
        EditAutoPaymentsEnum.autoPayNotAllowed.value &&
      this.checkUserProfileBasedSecurity(
        this.userProfileBasedSecurity.automaticPayments,
        ViewOnlyFullAccessSecurity.viewOnly
      )
    );
  }

  public get editAutoPaymentsPage(): boolean {
    return (
      this.showAutoPaymentsPage &&
      !this.isViewOnly &&
      this.checkUserProfileBasedSecurity(
        this.userProfileBasedSecurity.automaticPayments,
        ViewOnlyFullAccessSecurity.fullAccess
      ) &&
      (this.webLogin.isEmployee ||
        (this.useEntities
          ? this.entityParameters.editAutoPayments
          : this.parameters.editAutoPayments) ===
          EditAutoPaymentsEnum.growerCanEditPaySchedule.value)
    );
  }

  public get showPaymentSourcesPage(): boolean {
    return (
      this.showPayments &&
      (this.useEntities
        ? this.entityParameters.editPaymentSources
        : this.parameters.editPaymentSources) &&
      this.checkUserProfileBasedSecurity(
        this.userProfileBasedSecurity.paymentSources,
        ViewOnlyFullAccessSecurity.viewOnly
      )
    );
  }

  public get editPaymentSourcesPage(): boolean {
    return (
      this.showPaymentSourcesPage &&
      !this.isViewOnly &&
      this.checkUserProfileBasedSecurity(
        this.userProfileBasedSecurity.paymentSources,
        ViewOnlyFullAccessSecurity.fullAccess
      )
    );
  }

  public get showPendingPaymentsPage(): boolean {
    return (
      this.showPayments &&
      this.checkUserProfileBasedSecurity(
        this.userProfileBasedSecurity.viewPendingPayments,
        YesNoSecurity.yes
      )
    );
  }
}

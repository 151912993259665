import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import store from "@/store/index";
import { getRefreshToken } from "@/repositories/Web/WebLoginRepository";
import router from "@/router";
import { AlertType } from "@/enumerations/Alerts/AlertType";
import Alert from "@/interfaces/Alerts/Alert";
import Notification from "@/interfaces/Notifications/Notification";
import { NotificationType } from "@/enumerations/Notifications/NotificationType";

export default function UseVueAxios(
  apiKey: string,
  apiBaseUrl: string,
  customerPortalKey: string
) {
  const instance = axios.create();
  let isRefreshing = false;

  instance.interceptors.request.use((config) => {
    config.params = config.params || {};
    config.params["api-key"] = apiKey;
    config.headers = config.headers || {};
    config.headers["Customer-Portal-Key"] = customerPortalKey;
    if (config.headers["Customer-Id"] === undefined) {
      config.headers["Customer-Id"] =
        store.getters.webLoginModule.selectedAccount?.id ?? "";
    }
    config.headers["Entity-Id"] =
      store.getters.webLoginModule.selectedAccount?.entityId ?? "";

    if (store.getters.webLoginModule.tokens) {
      config.headers[
        "Authorization"
      ] = `Bearer ${store.getters.webLoginModule.tokens.jsonWebToken}`;
    }
    return config;
  });

  if (process.env.NODE_ENV === "production") {
    //do not set base URL when in development mode so that the dev server will proxy the request to avoid CORS errors
    instance.defaults.baseURL = apiBaseUrl;
  }

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response) {
        if (error.response.status === 422) {
          return error.response;
        }
        if (error.response.status === 404) {
          store.dispatch.notificationsModule.setNotification({
            message: "The requested resource was not found",
            type: NotificationType.Error,
          } as Notification);
          return;
        }
      }

      if (error.config.url === "api/customerPortal/v1/webLogin/refreshToken") {
        //token refresh failed, make user sign in again
        store.dispatch.webLoginModule.clearState();
        router.push("/login");
        store.dispatch.alertsModule.setAlert({
          headerMessage: "Session ended due to inactivity",
          type: AlertType.Information,
        } as Alert);
        return Promise.reject(error);
      }

      //attempt to refresh auth tokens
      if (error.config && error.response && error.response.status === 401) {
        const currentTokens = store.getters.webLoginModule.tokens;
        if (currentTokens) {
          if (!isRefreshing) {
            return new Promise((resolve, reject) => {
              if (
                `Bearer ${currentTokens.jsonWebToken}` ===
                error.config.headers["Authorization"]
              ) {
                isRefreshing = true;
                getRefreshToken(
                  currentTokens.jsonWebToken,
                  currentTokens.refreshToken
                )
                  .then((response) => {
                    if (response) {
                      store.dispatch.webLoginModule.setWebToken(response);
                      resolve(instance(error.config));
                    } else {
                      reject(instance(error.config));
                    }
                  })
                  .finally(() => (isRefreshing = false));
              } else {
                //another request already triggered the refresh token request that has already completed and updated the jwt
                //this request has managed to make it in here right after the jwt was refreshed from the other request
                //simply update the authorization header with the refreshed jwt and make the request again
                error.config.headers[
                  "Authorization"
                ] = `Bearer ${currentTokens.jsonWebToken}`;
                resolve(instance(error.config));
              }
            });
          } else {
            //wait for first request to set new tokens in store before sending subsequent requests
            return new Promise((resolve) => {
              const intervalId = setInterval(() => {
                if (!isRefreshing) {
                  clearInterval(intervalId);
                  resolve(instance(error.config));
                }
              }, 100);
            });
          }
        }
      } else {
        store.dispatch.alertsModule.setAlert({
          headerMessage: error,
          type: AlertType.Error,
        } as Alert);
      }
    }
  );

  Vue.use(VueAxios, instance);
}

import Vue from "vue";
import Vuex from "vuex";
import { createDirectStore } from "direct-vuex";
import alertsModule from "./alerts";
import parametersModule from "./parameters";
import notificationsModule from "./notifications";
import webLoginModule from "./webLogin";
import configurationModule from "./configuration";

Vue.use(Vuex);

const {
  store,
  rootActionContext,
  moduleActionContext,
  rootGetterContext,
  moduleGetterContext,
} = createDirectStore({
  modules: {
    alertsModule,
    parametersModule,
    notificationsModule,
    webLoginModule,
    configurationModule,
  },
});

// Export the direct-store instead of the classic Vuex store.
export default store;

// The following exports will be used to enable types in the
// implementation of actions and getters.
export {
  rootActionContext,
  moduleActionContext,
  rootGetterContext,
  moduleGetterContext,
};

// The following lines enable types in the injected store '$store'.
export type AppStore = typeof store;
declare module "vuex" {
  // eslint-disable-next-line
  interface Store<S> {
    direct: AppStore;
  }
}

import dayjs from "dayjs";
import { PredefinedDateRangeType } from "@/enumerations/Dates/DateRangePredefined/PredefinedDateRangeType";

export const dateTimeFormat = "MM/DD/YYYY hh:mm A";
export const dateTimeShortFormat = "M/D H:mm";
export const periodFormat = "MMMM YYYY";
export const standardFormat = "MM/DD/YYYY";
export const extendedFormat = "MMMM D, YYYY";
export const extendedFormatWithWeekday = "dddd, MMMM D, YYYY";

export function getPredefinedDateRangeStartDate(
  currentDate: dayjs.Dayjs,
  presetDateRangeType: string
): dayjs.Dayjs {
  let presetFromDate = currentDate;

  if (presetDateRangeType === PredefinedDateRangeType.ThreeMonths) {
    presetFromDate = currentDate.add(-3, "month").set("date", 1);
  } else if (presetDateRangeType === PredefinedDateRangeType.SixMonths) {
    presetFromDate = currentDate.add(-6, "month").set("date", 1);
  } else if (presetDateRangeType === PredefinedDateRangeType.OneYear) {
    presetFromDate = currentDate.add(-1, "year").set("date", 1);
  } else if (presetDateRangeType === PredefinedDateRangeType.CurrentYear) {
    presetFromDate = currentDate.set("date", 1).set("month", 0);
  } else if (presetDateRangeType === PredefinedDateRangeType.PreviousYear) {
    presetFromDate = currentDate
      .set("date", 1)
      .set("month", 0)
      .set("year", dayjs().add(-1, "year").get("year"));
  }

  return presetFromDate;
}

export function getPredefinedDateRangeEndDate(
  currentDate: dayjs.Dayjs,
  presetDateRangeType: string
): dayjs.Dayjs {
  let presetToDate = currentDate;

  if (presetDateRangeType === PredefinedDateRangeType.PreviousYear) {
    presetToDate = currentDate
      .set("month", 11)
      .set("date", 31)
      .set("year", dayjs().add(-1, "year").get("year"));
  }

  return presetToDate;
}

export function getFormattedDateString(
  date: string | Date | dayjs.Dayjs,
  format = standardFormat
): string {
  if (!date) return "";
  return dayjs(date).format(format).toString();
}

export function getRelativeTimeAgo(date: string | Date | dayjs.Dayjs): string {
  if (!date) return "";
  return dayjs(date).fromNow();
}

export function getIsoDateString(date: string | Date | dayjs.Dayjs): string {
  if (!date) return "";
  return dayjs(date).toISOString().substring(0, 10);
}

export function getOneYearFromToday(currentDate: dayjs.Dayjs): string {
  return currentDate.add(1, "year").toISOString();
}

export function getFirstDayOfTheMonth(currentDate: dayjs.Dayjs): string {
  return currentDate.startOf("month").toISOString();
}

export function getLastDayOfTheMonth(currentDate: dayjs.Dayjs | Date): string {
  return dayjs(currentDate)
    .set("date", dayjs(currentDate).daysInMonth())
    .toISOString();
}

export function getCurrentDate(): dayjs.Dayjs {
  return dayjs();
}

export function getCustomOrPredefinedStartDate(
  isCustomDateRange: boolean,
  customStartDate: string,
  predefinedDateRangeTypeValue: string
): string {
  const fromDate = isCustomDateRange
    ? customStartDate
    : predefinedDateRangeTypeValue === PredefinedDateRangeType.AllDates
    ? ""
    : getPredefinedDateRangeStartDate(
        getCurrentDate(),
        predefinedDateRangeTypeValue
      );

  return getFormattedDateString(fromDate);
}

export function getCustomOrPredefinedEndDate(
  isCustomDateRange: boolean,
  customEndDate: string,
  predefinedDateRangeTypeValue: string
): string {
  const toDate = isCustomDateRange
    ? customEndDate
    : predefinedDateRangeTypeValue === PredefinedDateRangeType.AllDates
    ? ""
    : getPredefinedDateRangeEndDate(
        getCurrentDate(),
        predefinedDateRangeTypeValue
      );

  return getFormattedDateString(toDate);
}

export function getDayOfMonth(date: string | Date | dayjs.Dayjs): number {
  return dayjs(date).date();
}

export function getDayOfWeek(date: string | Date | dayjs.Dayjs): string {
  return dayjs(date).format("dddd");
}

export function getDateOrdinal(day: number): string {
  return (
    day +
    (31 === day || 21 === day || 1 === day
      ? "st"
      : 22 === day || 2 === day
      ? "nd"
      : 23 === day || 3 === day
      ? "rd"
      : "th")
  );
}

export function getMonthName(month: number): string {
  return getCurrentDate().month(month).format("MMMM");
}

import YesNoSecurity from "@/enumerations/Security/YesNoSecurity";
import SecuritySettingsBase from "./SecuritySettingsBase";

export default class PurchasesSecuritySettings extends SecuritySettingsBase {
  private static instance: PurchasesSecuritySettings;

  private constructor() {
    super();
  }

  public static getInstance(): PurchasesSecuritySettings {
    if (!PurchasesSecuritySettings.instance) {
      PurchasesSecuritySettings.instance = new PurchasesSecuritySettings();
    }

    return PurchasesSecuritySettings.instance;
  }

  private get showPurchases(): boolean {
    return (
      !this.isManagerAccountSelected &&
      this.checkUserProfileBasedSecurity(
        this.userProfileBasedSecurity.purchases,
        YesNoSecurity.yes
      )
    );
  }

  public get showPurchasesMenu(): boolean {
    return (
      this.showPurchases &&
      (this.showViewCreditActivityPage ||
        this.showViewPurchasesPage ||
        this.showViewTransactionsPage ||
        this.showViewStatementsPage ||
        this.showViewPurchaseSummaryPage)
    );
  }

  public get showViewCreditActivityPage(): boolean {
    return (
      this.parameters.showCreditActivity &&
      this.showPurchases &&
      this.checkUserProfileBasedSecurity(
        this.userProfileBasedSecurity.viewCreditActivity,
        YesNoSecurity.yes
      )
    );
  }

  public get showViewPurchasesPage(): boolean {
    return (
      this.parameters.showPurchases &&
      this.showPurchases &&
      this.checkUserProfileBasedSecurity(
        this.userProfileBasedSecurity.viewPurchases,
        YesNoSecurity.yes
      )
    );
  }

  public get showViewTransactionsPage(): boolean {
    return (
      this.parameters.showItemsPurchased &&
      this.showPurchases &&
      this.checkUserProfileBasedSecurity(
        this.userProfileBasedSecurity.viewPurchaseTransactions,
        YesNoSecurity.yes
      )
    );
  }

  public get showViewStatementsPage(): boolean {
    return (
      this.parameters.showStatementsMenu &&
      this.showPurchases &&
      this.checkUserProfileBasedSecurity(
        this.userProfileBasedSecurity.viewStatements,
        YesNoSecurity.yes
      )
    );
  }

  public get showViewPurchaseSummaryPage(): boolean {
    return (
      this.parameters.showPurchaseSummary &&
      this.showPurchases &&
      this.checkUserProfileBasedSecurity(
        this.userProfileBasedSecurity.viewPurchaseSummary,
        YesNoSecurity.yes
      )
    );
  }
}

const ViewAutoPayments = () =>
  import(
    /* webpackChunkName: "PaymentsViewAutoPayments" */ "@/views/Payments/ViewAutoPayments.vue"
  );
const CreatePayment = () =>
  import(
    /* webpackChunkName: "PaymentsCreatePayment" */ "@/views/Payments/CreatePayment.vue"
  );
const ViewPaymentHistory = () =>
  import(
    /* webpackChunkName: "PaymentsViewPaymentHistory" */ "@/views/Payments/ViewPaymentHistory.vue"
  );
const ViewPaymentSources = () =>
  import(
    /* webpackChunkName: "PaymentsViewPaymentSources" */ "@/views/Payments/ViewPaymentSources.vue"
  );
const ViewPendingPayments = () =>
  import(
    /* webpackChunkName: "PaymentsViewPendingPayments" */ "@/views/Payments/ViewPendingPayments.vue"
  );
import { RouteConfig } from "vue-router";
import PaymentsSecuritySettings from "@/helpers/Security/PaymentsSecuritySettings";

const paymentsSecuritySettings = PaymentsSecuritySettings.getInstance();

const paymentMenuRoutes: RouteConfig[] = [
  {
    path: "/payment/automaticPayments",
    name: "automatic-payments",
    component: ViewAutoPayments,
    meta: {
      displayName: "Automatic Payments",
    },
    beforeEnter: (_to, _from, next) => {
      if (paymentsSecuritySettings.showAutoPaymentsPage) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/payment/create",
    name: "create-payment",
    component: CreatePayment,
    meta: {
      displayName: "Make a Payment",
    },
    beforeEnter: (_to, _from, next) => {
      if (paymentsSecuritySettings.showCreatePaymentPage) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/payment/history",
    name: "payment-history",
    component: ViewPaymentHistory,
    meta: {
      displayName: "Payment History",
    },
    beforeEnter: (_to, _from, next) => {
      if (paymentsSecuritySettings.showPaymentHistoryPage) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/payment/pending",
    name: "pending-payments",
    component: ViewPendingPayments,
    meta: {
      displayName: "Pending Payments",
    },
    beforeEnter: (_to, _from, next) => {
      if (paymentsSecuritySettings.showPendingPaymentsPage) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/payment/sources",
    name: "payment-sources",
    component: ViewPaymentSources,
    meta: {
      displayName: "Payment Sources",
    },
    beforeEnter: (_to, _from, next) => {
      if (paymentsSecuritySettings.showPaymentSourcesPage) {
        next();
      } else {
        next("/");
      }
    },
  },
];

export default paymentMenuRoutes;

import ConfigurationFileContents from "@/interfaces/Configuration/ConfigurationFileContents";

export async function getRuntimeConfiguration(): Promise<ConfigurationFileContents> {
  const getRuntimeConfigurationFile = async () => {
    if (process.env.NODE_ENV === "development") {
      return require("@/config.json");
    } else {
      const runtimeConfig = await fetch("/config.json");
      return await runtimeConfig.json();
    }
  };

  let contents = {} as ConfigurationFileContents;
  await getRuntimeConfigurationFile().then((configJson) => {
    contents = {
      publicApiKey: configJson.API_KEY,
      publicApiBaseUrl: configJson.API_BASE_URL,
      customerPortalKey: configJson.CUSTOMER_PORTAL_KEY,
      contentManagementSystemBaseUrl:
        configJson.CONTENT_MANAGEMENT_SYSTEM_BASE_URL,
      portalThemeVariables: configJson.PORTAL_THEME_VARIABLES,
    } as ConfigurationFileContents;
  });

  return contents;
}

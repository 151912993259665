import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import UseVuetify from "./plugins/vuetify";
import "./plugins/dayjs";
import "./plugins/numeral";
import UseVueAxios from "./plugins/vueAxios";
import "./plugins/vueMediaQ";
import { getRuntimeConfiguration } from "@/helpers/Configuration/ConfigurationFileHelpers";
import ConfigurationFileContents from "@/interfaces/Configuration/ConfigurationFileContents";
import "@/assets/css/main.css";

Vue.config.productionTip = false;

getRuntimeConfiguration().then(function (config: ConfigurationFileContents) {
  UseVueAxios(
    config.publicApiKey,
    config.publicApiBaseUrl,
    config.customerPortalKey
  );
  const vuetify = UseVuetify(config.portalThemeVariables);

  store.dispatch.configurationModule.setConfigurationContents(config);

  new Vue({
    router,
    store: store.original,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
});

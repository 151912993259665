import WebLoginAccount from "@/interfaces/WebLogin/WebLoginAccount";
import RepositoryRequest from "@/helpers/Repositories/RepositoryRequest";
import RepositoryRequestResponse from "@/interfaces/Repository/Requests/RepositoryRequestResponse";

const END_POINT = "api/customerPortal/v1/webMap";

export async function getAccounts(
  customerId = "",
  customerName = "",
  isEmployee = false
): Promise<WebLoginAccount[]> {
  const getRequestParameters = {
    customerId: isEmployee && customerId !== "" ? `*${customerId}*` : "",
    customerName: isEmployee && customerName !== "" ? `*${customerName}*` : "",
  };

  return await new RepositoryRequest()
    .WithUrl(END_POINT)
    .WithFilter(getRequestParameters)
    .GetData<WebLoginAccount[]>();
}

export async function addAccount(
  storeNumber: string,
  accountNumber: string,
  invoiceNumber: string
): Promise<RepositoryRequestResponse> {
  return await new RepositoryRequest().WithUrl(END_POINT).Post({
    locationId: storeNumber,
    accountId: accountNumber,
    invoiceNumber: invoiceNumber,
  });
}

export async function removeAccount(
  accountId: string,
  entityId: string
): Promise<RepositoryRequestResponse> {
  return await new RepositoryRequest()
    .WithUrl(`${END_POINT}/${accountId}/${entityId}`)
    .Delete();
}

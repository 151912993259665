export function keywordTermStartsWithSearchTerm(
  keywordTerm: string,
  searchTerm: string
): boolean {
  if (!keywordTerm || !searchTerm) return true;
  return keywordTerm.toLowerCase().startsWith(searchTerm.toLowerCase());
}

export function keywordMatchesSearchText(
  keyword: string,
  searchQuery: string
): boolean {
  if (!searchQuery) return true;

  for (const searchTerm of searchQuery.split(" ")) {
    if (!searchTerm) continue;
    for (const keywordTerm of keyword.split(" ")) {
      if (keywordTermStartsWithSearchTerm(keywordTerm, searchTerm)) {
        return true;
      }
    }
  }
  return false;
}

export function getMatchingKeywords(
  availableKeywords: string[],
  searchQuery: string
): string[] {
  if (!searchQuery) return availableKeywords;

  const matchedKeywords = [] as string[];

  for (const availableKeyword of availableKeywords) {
    if (keywordMatchesSearchText(availableKeyword, searchQuery)) {
      matchedKeywords.push(availableKeyword);
    }
  }

  return matchedKeywords;
}

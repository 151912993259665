
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import AccountPasswordResetFormRequirementIndicator from "@/components/Account/AccountPasswordResetFormRequirementIndicator.vue";
import {
  validatePasswordMeetsRequirements,
  PasswordValidateRegex,
} from "@/helpers/Account/AccountPasswordResetHelpers";
import AccountPasswordResetRequirements from "@/interfaces/Account/PasswordReset/AccountPasswordResetRequirements";
import {
  resetPassword,
  resetPasswordWithToken,
} from "@/repositories/Web/WebLoginRepository";
import Notification from "@/interfaces/Notifications/Notification";
import { NotificationType } from "@/enumerations/Notifications/NotificationType";
import WebLoginAuthentication from "@/interfaces/WebLogin/WebLoginAuthentication";
import UnprocessableEntityReponse from "@/interfaces/Repository/UnprocessableEntityResponse";

@Component({ components: { AccountPasswordResetFormRequirementIndicator } })
export default class AccountPasswordResetForm extends Vue {
  @Prop({ default: "" })
  title!: string;

  @Prop({ default: null })
  resetToken!: string | null;

  store = this.$store.direct;
  isLoading = false;
  username = "";
  usernameErrors: string[] = [];
  oldPassword = "";
  oldPasswordErrors: string[] = [];
  newPassword = "";
  newPasswordErrors: string[] = [];
  newPasswordFocused = false;
  confirmPassword = "";
  confirmPasswordErrors: string[] = [];
  confirmPasswordFocused = false;
  requirements = {} as AccountPasswordResetRequirements;
  errorMessage = "";

  get buttonText(): string {
    return this.resetToken ? "Reset Password" : "Change Password";
  }

  async resetPassword(): Promise<void> {
    //reset focused here since having the input set it on blur forces user to click reset button twice
    this.confirmPasswordFocused = false;
    this.errorMessage = "";
    if (!this.validate()) return;

    this.isLoading = true;

    if (this.resetToken) {
      await this.resetPasswordByToken();
    } else {
      await this.resetPasswordByOldPassword();
    }

    this.isLoading = false;
  }

  async resetPasswordByToken(): Promise<void> {
    if (!this.resetToken) return;

    const response = await resetPasswordWithToken(
      this.resetToken,
      this.username,
      this.newPassword
    );
    if (response.status === 422) {
      const errors = response.data as UnprocessableEntityReponse[];
      this.errorMessage = errors
        .filter((x) => x.property === "")
        .map((x) => x.message)[0];
    } else {
      const webAuthentication = response.data as WebLoginAuthentication;
      this.store.dispatch.webLoginModule.setWebToken(webAuthentication.tokens);
      this.$router.push("/home");
    }
  }

  async resetPasswordByOldPassword(): Promise<void> {
    if (this.store.getters.webLoginModule.tokens === undefined) return;

    await resetPassword(
      this.store.getters.webLoginModule.tokens.jsonWebToken,
      this.oldPassword,
      this.newPassword
    ).then((response) => {
      if (response.invalidCredentials) {
        this.oldPasswordErrors.push("Invalid password");
        return;
      }

      const form = this.$refs.form as HTMLFormElement;
      form?.reset();

      this.store.dispatch.webLoginModule.setWebToken(response.tokens);

      this.store.dispatch.notificationsModule.setNotification({
        message: "Password successfully reset",
        type: NotificationType.Success,
      } as Notification);
      this.$emit("account-password-reset-form-success");
    });
  }

  validate(): boolean {
    let hasErrors = false;
    this.usernameErrors = [];
    this.oldPasswordErrors = [];
    this.newPasswordErrors = [];
    this.confirmPasswordErrors = [];

    if (this.resetToken && this.username.length === 0) {
      hasErrors = true;
      this.usernameErrors.push("Required");
    }

    if (!this.resetToken && this.oldPassword.length === 0) {
      hasErrors = true;
      this.oldPasswordErrors.push("Required");
    }

    if (this.oldPassword === this.newPassword) {
      hasErrors = true;
      this.oldPasswordErrors.push("New and old passwords cannot be the same");
    }

    if (!PasswordValidateRegex.test(this.newPassword)) {
      hasErrors = true;
      this.newPasswordErrors.push("Password does not meet all requirements");
    }

    if (!this.passwordsMatch) {
      hasErrors = true;
      this.confirmPasswordErrors.push("Passwords do not match");
    }

    return !hasErrors;
  }

  cancelClicked(): void {
    this.$emit("onCancel");
  }

  @Watch("newPassword")
  newPasswordChanged(newValue: string) {
    this.requirements = validatePasswordMeetsRequirements(newValue);
  }

  get passwordsMatch() {
    return (
      this.newPassword.length > 0 && this.confirmPassword === this.newPassword
    );
  }
}

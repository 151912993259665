import { BaseEnumeration } from "@/enumerations/BaseEnumeration";
import BaseEnumerationMember from "@/enumerations/BaseEnumerationMember";
export default class EditAutoPayments extends BaseEnumeration<number> {
  public static autoPayNotAllowed = new BaseEnumerationMember(
    "Auto Pay Not Allowed",
    0
  );
  public static growerCanEditPaySchedule = new BaseEnumerationMember(
    "Auto Pay and Grower Can Edit Pay Schedule",
    1
  );
  public static growerCannotEditPaySchedule = new BaseEnumerationMember(
    "Auto Pay and Grower Cannot Edit Pay Schedule",
    2
  );

  public GetContents(): BaseEnumerationMember<number>[] {
    return [
      EditAutoPayments.autoPayNotAllowed,
      EditAutoPayments.growerCanEditPaySchedule,
      EditAutoPayments.growerCannotEditPaySchedule,
    ];
  }
}

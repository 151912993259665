
import { Vue, Component } from "vue-property-decorator";
import { forgotPassword } from "@/repositories/Web/WebLoginRepository";

@Component
export default class ForgotPassword extends Vue {
  isLoading = false;
  isValid = true;
  email = "";
  username = "";
  errorMessage = "";

  private async retrieve(): Promise<void> {
    this.isValid = true;
    if (!this.email.trim() && !this.username.trim()) {
      this.errorMessage = "Either a email address or username is required";
      this.isValid = false;
      return;
    }

    this.errorMessage = "";
    const form = this.$refs.form as HTMLFormElement;
    form?.validate();

    if (!this.isValid) return;
    await forgotPassword(this.email, this.username);
    this.$emit("onRetrieved");
  }
}

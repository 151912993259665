
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { login } from "@/repositories/Web/WebLoginRepository";
import Alert from "@/interfaces/Alerts/Alert";
import { AlertType } from "@/enumerations/Alerts/AlertType";
import { getUserProfileBasedSecurity } from "@/repositories/Security/UserProfileBasedSecurityRepository";
import EmployeeSecurity from "@/enumerations/Security/EmployeeSecurity";

@Component
export default class LoginForm extends Vue {
  @Prop()
  onChanged!: boolean;

  store = this.$store.direct;
  isFormValid = false;
  isLoading = false;
  userName = "";
  password = "";
  keepLoggedIn = false;
  errorMessage = "";

  private async login(): Promise<void> {
    this.errorMessage = "";
    const form = this.$refs.form as HTMLFormElement;
    form?.validate();
    if (!this.isFormValid) return;

    try {
      this.isLoading = true;

      const loginAttempt = await login(
        this.userName,
        this.password,
        this.keepLoggedIn ?? false
      );

      if (loginAttempt.isAccountLocked) {
        this.errorMessage = "";
        this.store.dispatch.alertsModule.setAlert({
          headerMessage:
            "Your account has been locked due to too many failed login attempts",
          bodyMessage:
            "Please contact us to unlock your account or use the Forgot Password link to reset your password",
          type: AlertType.Error,
        } as Alert);
        return;
      }

      if (loginAttempt.invalidCredentials) {
        this.errorMessage = "Invalid username or password";
        return;
      }

      await this.store.dispatch.webLoginModule.setWebToken(loginAttempt.tokens);

      if (
        this.store.getters.webLoginModule.webLogin.isEmployee &&
        this.store.getters.webLoginModule.webLogin.employeeSecurity ==
          EmployeeSecurity.userProfile
      ) {
        const userProfileBasedSecurity = await getUserProfileBasedSecurity();
        this.store.dispatch.webLoginModule.setUserProfileBasedSecurity(
          userProfileBasedSecurity
        );
      }

      this.$router.push("/home");
    } finally {
      this.isLoading = false;
    }
  }

  @Watch("onChanged")
  reset(): void {
    const form = this.$refs.form as HTMLFormElement;
    form?.reset();
  }
}


import { Vue, Component, PropSync } from "vue-property-decorator";
import store from "@/store";

@Component
export default class SearchAccountCard extends Vue {
  valid = true;

  @PropSync("customerId")
  customerIdSync!: string;

  @PropSync("customerName")
  customerNameSync!: string;

  get forceSelectAccount(): boolean {
    return !store.getters.webLoginModule.selectedAccount;
  }

  changePassword(): void {
    this.$emit("changePassword");
  }

  addAccount(): void {
    this.$emit("addAccount");
  }

  cancelClicked(): void {
    this.$emit("onCancel");
  }

  searchClicked(): void {
    this.valid = true;
    if (
      (this.customerIdSync == null || this.customerIdSync.trim() === "") &&
      (this.customerNameSync == null || this.customerNameSync.trim() === "")
    ) {
      this.valid = false;
      return;
    }

    this.$emit("onSearch");
  }
}

import YesNoSecurity from "@/enumerations/Security/YesNoSecurity";
import SecuritySettingsBase from "./SecuritySettingsBase";

export default class OrdersSecuritySettings extends SecuritySettingsBase {
  private static instance: OrdersSecuritySettings;

  private constructor() {
    super();
  }

  public static getInstance(): OrdersSecuritySettings {
    if (!OrdersSecuritySettings.instance) {
      OrdersSecuritySettings.instance = new OrdersSecuritySettings();
    }

    return OrdersSecuritySettings.instance;
  }

  private get showOrders(): boolean {
    return (
      !this.isManagerAccountSelected &&
      this.checkUserProfileBasedSecurity(
        this.userProfileBasedSecurity.orders,
        YesNoSecurity.yes
      )
    );
  }

  public get showOrdersMenu(): boolean {
    return (
      this.showOrders && (this.showViewOrdersPage || this.showCreateOrderPage)
    );
  }

  public get showViewOrdersPage(): boolean {
    return (
      this.parameters.showPurchases &&
      this.showOrders &&
      this.checkUserProfileBasedSecurity(
        this.userProfileBasedSecurity.viewOrders,
        YesNoSecurity.yes
      )
    );
  }

  public get showCreateOrderPage(): boolean {
    return (
      this.parameters.allowPlaceOrders &&
      !this.isViewOnly &&
      this.showOrders &&
      this.checkUserProfileBasedSecurity(
        this.userProfileBasedSecurity.createOrder,
        YesNoSecurity.yes
      )
    );
  }
}

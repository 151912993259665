import Vue from "vue";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import minMax from "dayjs/plugin/minMax";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

import {
  getFormattedDateString,
  standardFormat,
  extendedFormat,
  getRelativeTimeAgo,
  dateTimeShortFormat,
  periodFormat,
  extendedFormatWithWeekday,
} from "@/helpers/Dates/DateHelpers";

dayjs.extend(relativeTime);
dayjs.extend(minMax);
dayjs.extend(isSameOrAfter);

Vue.filter(
  "formatDate",
  (value: string | Date, format: string = standardFormat): string =>
    getFormattedDateString(value, format)
);

Vue.filter("formatDateExtended", (value: string | Date): string =>
  getFormattedDateString(value, extendedFormat)
);

Vue.filter("formatDateExtendedWithWeekday", (value: string | Date): string =>
  getFormattedDateString(value, extendedFormatWithWeekday)
);

Vue.filter("formatDateTimeShort", (value: string | Date): string =>
  getFormattedDateString(value, dateTimeShortFormat)
);

Vue.filter("formatPeriod", (value: string | Date): string =>
  getFormattedDateString(value, periodFormat)
);

Vue.filter("relativeTimeAgo", (value: string | Date): string =>
  getRelativeTimeAgo(value)
);

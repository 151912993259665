import axios, { AxiosInstance } from "axios";
import store from "@/store";
import NewsArticle from "@/interfaces/ContentMangementSystem/NewsArticle";
import CarouselItem from "@/interfaces/ContentMangementSystem/CarouselItem";

function getCmsBaseUrl(): string {
  return store.getters.configurationModule.configurationContents
    .contentManagementSystemBaseUrl;
}

function getAxiosInstance(): AxiosInstance {
  const instance = axios.create();
  if (process.env.NODE_ENV === "production") {
    //do not set base URL when in development mode so that the dev server will proxy the request to avoid CORS errors
    instance.defaults.baseURL = `${getCmsBaseUrl()}`;
  }
  return instance;
}

function getImageUrl(baseUrl: string, url: string): string {
  if (url) {
    return `${baseUrl}${url.substring(url.indexOf("/media"))}`;
  }
  return "";
}

export async function getCarouselItems(): Promise<CarouselItem[]> {
  const query = `{
        carouselItem {
            backgroundColor
            backgroundImage {
                urls
            }
            externalLink 
            overlayText
            dialogText
        }
    }`;

  const response = await getAxiosInstance().get("/api/graphql", {
    params: { query: query },
  });
  const carouselItems = response.data.data.carouselItem as CarouselItem[];
  carouselItems.forEach((x) => {
    if (x.backgroundImage.urls[0]) {
      x.backgroundImage.urls[0] = getImageUrl(
        getCmsBaseUrl(),
        x.backgroundImage.urls[0]
      );
    }
  });
  return carouselItems;
}

export async function getLoginPageCarouselItems(): Promise<CarouselItem[]> {
  const query = `{
        loginPageCarouselItem {
            backgroundColor
            backgroundImage {
                urls
            }
            externalLink 
            overlayText
            dialogText
        }
    }`;

  const response = await getAxiosInstance().get("/api/graphql", {
    params: { query: query },
  });
  const carouselItems = response.data.data
    .loginPageCarouselItem as CarouselItem[];
  carouselItems.forEach((x) => {
    if (x.backgroundImage.urls[0]) {
      x.backgroundImage.urls[0] = getImageUrl(
        getCmsBaseUrl(),
        x.backgroundImage.urls[0]
      );
    }
  });
  return carouselItems;
}

export async function getNewsArticles(): Promise<NewsArticle[]> {
  const query = `{
        newsArticle {
            previewImage {
                urls(first: 1)
            }
            externalLink {
                url
                text
            }
            displayDate
        }
    }`;

  const response = await getAxiosInstance().get("/api/graphql", {
    params: { query: query },
  });
  const articles = response.data.data.newsArticle as NewsArticle[];
  articles.forEach((x) => {
    if (x.previewImage.urls[0]) {
      x.previewImage.urls[0] = getImageUrl(
        getCmsBaseUrl(),
        x.previewImage.urls[0]
      );
    }
  });
  return articles.sort((a, b) => b.displayDate.localeCompare(a.displayDate));
}

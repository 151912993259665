import WebLogin from "@/interfaces/WebLogin/WebLogin";
import WebLoginAccount from "@/interfaces/WebLogin/WebLoginAccount";
import WebLoginAuthenticationTokens from "@/interfaces/WebLogin/WebLoginAuthenticationTokens";
import { getWebLoginFromJsonWebToken } from "@/helpers/WebLogin/WebLoginHelpers";
import store from "@/store";
import UserProfileBasedSecurity from "@/interfaces/Security/UserProfileBasedSecurity";
import WebLoginAccountFactory from "@/factories/WebLogin/WebLoginAccountFactory";

const SET_WEB_LOGIN_SELECTED_ACCOUNT = "SET_WEB_LOGIN_SELECTED_ACCOUNT";
const SET_WEB_LOGIN_AUTHENTICATION_TOKENS =
  "SET_WEB_LOGIN_AUTHENTICATION_TOKENS";
const SET_WEB_LOGIN = "SET_WEB_LOGIN";
const CLEAR_WEB_LOGIN_STATE = "CLEAR_WEB_LOGIN_STATE";
const SET_WEB_LOGIN_USER_BASED_SECURITY = "SET_WEB_LOGIN_USER_BASED_SECURITY";

export interface WebLoginState {
  selectedAccount: WebLoginAccount | undefined;
  webLogin: WebLogin;
  tokens: WebLoginAuthenticationTokens | undefined;
  userProfileBasedSecurity: UserProfileBasedSecurity | undefined;
}
export default {
  namespaced: true as const,
  state: {
    selectedAccount: undefined,
    webLogin: {} as WebLogin,
    tokens: undefined,
    userProfileBasedSecurity: undefined,
  } as WebLoginState,
  getters: {
    selectedAccount: (state: WebLoginState) => state.selectedAccount,
    webLogin: (state: WebLoginState) => state.webLogin,
    tokens: (state: WebLoginState) => state.tokens,
    userProfileBasedSecurity: (state: WebLoginState) =>
      state.userProfileBasedSecurity,
  },
  mutations: {
    [SET_WEB_LOGIN_SELECTED_ACCOUNT](
      state: WebLoginState,
      newSelectedAccount: WebLoginAccount | undefined
    ) {
      state.selectedAccount = newSelectedAccount
        ? { ...newSelectedAccount }
        : undefined;
    },
    [SET_WEB_LOGIN_AUTHENTICATION_TOKENS](
      state: WebLoginState,
      newTokens: WebLoginAuthenticationTokens | undefined
    ) {
      state.tokens = newTokens ? { ...newTokens } : undefined;
    },
    [SET_WEB_LOGIN](state: WebLoginState, newWebLogin: WebLogin) {
      state.webLogin = newWebLogin;
    },
    [SET_WEB_LOGIN_USER_BASED_SECURITY](
      state: WebLoginState,
      newUserbasedSecurity: UserProfileBasedSecurity | undefined
    ) {
      state.userProfileBasedSecurity = newUserbasedSecurity;
    },
    [CLEAR_WEB_LOGIN_STATE](state: WebLoginState) {
      state.selectedAccount = undefined;
      state.webLogin = {} as WebLogin;
      state.tokens = undefined;
      state.userProfileBasedSecurity = undefined;
    },
  },
  actions: {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    setSelectedAccount(context: any, payload: WebLoginAccount) {
      const selectedAccount = new WebLoginAccountFactory(payload).getInstance();
      const storage: Storage = context.state.webLogin.keepLoggedIn
        ? localStorage
        : sessionStorage;
      storage.setItem("selectedAccount", JSON.stringify(selectedAccount));
      context.commit(SET_WEB_LOGIN_SELECTED_ACCOUNT, selectedAccount);

      if (selectedAccount.entityId !== "") {
        store.dispatch.parametersModule.setEntityParameterSettings();
      }
    },
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    setWebToken(context: any, payload: WebLoginAuthenticationTokens) {
      context.commit(
        SET_WEB_LOGIN,
        getWebLoginFromJsonWebToken(payload.jsonWebToken)
      );
      const storage: Storage = context.state.webLogin.keepLoggedIn
        ? localStorage
        : sessionStorage;
      storage.setItem("tokens", JSON.stringify(payload));
      context.commit(SET_WEB_LOGIN_AUTHENTICATION_TOKENS, payload);
    },
    setUserProfileBasedSecurity(
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      context: any,
      payload: UserProfileBasedSecurity
    ) {
      context.commit(SET_WEB_LOGIN_USER_BASED_SECURITY, payload);
      const storage: Storage = context.state.webLogin.keepLoggedIn
        ? localStorage
        : sessionStorage;
      storage.setItem("userProfileBasedSecurity", JSON.stringify(payload));
    },
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    restoreTokens(context: any) {
      const tokensJson =
        sessionStorage.getItem("tokens") ??
        localStorage.getItem("tokens") ??
        undefined;

      if (tokensJson) {
        const tokens = JSON.parse(tokensJson) as WebLoginAuthenticationTokens;
        context.commit(SET_WEB_LOGIN_AUTHENTICATION_TOKENS, tokens);
        context.commit(
          SET_WEB_LOGIN,
          getWebLoginFromJsonWebToken(tokens.jsonWebToken)
        );

        const selectedAccountJson = context.state.webLogin.keepLoggedIn
          ? localStorage.getItem("selectedAccount")
          : sessionStorage.getItem("selectedAccount");
        if (selectedAccountJson) {
          const selectedAccount = JSON.parse(
            selectedAccountJson
          ) as WebLoginAccount;
          context.commit(SET_WEB_LOGIN_SELECTED_ACCOUNT, selectedAccount);

          if (selectedAccount.entityId !== "") {
            store.dispatch.parametersModule.setEntityParameterSettings();
          }
        }
      } else {
        context.commit(SET_WEB_LOGIN_AUTHENTICATION_TOKENS, undefined);
        context.commit(SET_WEB_LOGIN_SELECTED_ACCOUNT, undefined);
      }
    },
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    restoreUserBasedSecurity(context: any) {
      const userProfileBasedSecurityJson = context.state.webLogin.keepLoggedIn
        ? localStorage.getItem("userProfileBasedSecurity")
        : sessionStorage.getItem("userProfileBasedSecurity");
      if (userProfileBasedSecurityJson) {
        const userProfileBasedSecurity = JSON.parse(
          userProfileBasedSecurityJson
        ) as UserProfileBasedSecurity;
        context.commit(
          SET_WEB_LOGIN_USER_BASED_SECURITY,
          userProfileBasedSecurity
        );
      } else {
        context.commit(SET_WEB_LOGIN_USER_BASED_SECURITY, undefined);
      }
    },
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    clearState(context: any) {
      localStorage.removeItem("tokens");
      sessionStorage.removeItem("tokens");
      localStorage.removeItem("selectedAccount");
      sessionStorage.removeItem("selectedAccount");
      localStorage.removeItem("userProfileBasedSecurity");
      sessionStorage.removeItem("userProfileBasedSecurity");
      context.commit(CLEAR_WEB_LOGIN_STATE);
    },
  },
};

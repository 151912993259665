const ViewBookings = () =>
  import(
    /* webpackChunkName: "ContractsViewBookings" */ "@/views/Contracts/ViewBookings.vue"
  );
const ViewPrepaids = () =>
  import(
    /* webpackChunkName: "ContractsViewPrepaids" */ "@/views/Contracts/ViewPrepaids.vue"
  );
import { RouteConfig } from "vue-router";
import ContractsSecuritySettings from "@/helpers/Security/ContractsSecuritySettings";

const contractsSecuritySettings = ContractsSecuritySettings.getInstance();

const purchasesMenuRoutes: RouteConfig[] = [
  {
    path: "/bookings/view",
    name: "view-bookings",
    component: ViewBookings,
    meta: {
      displayName: "View Bookings",
    },
    beforeEnter: (to, from, next) =>
      contractsSecuritySettings.showViewBookingsPage ? next() : next("/"),
  },
  {
    path: "/prepaids/view",
    name: "view-prepaids",
    component: ViewPrepaids,
    meta: {
      displayName: "View Prepaids",
    },
    beforeEnter: (to, from, next) =>
      contractsSecuritySettings.showViewPrepaidsPage ? next() : next("/"),
  },
];

export default purchasesMenuRoutes;

/**
 * Takes an interface and defaults any properties that are undefined.
 */
export default abstract class InterfaceFactory<T> {
  protected interfaceInstance: T;

  protected abstract DefaultInterface: T;

  constructor(interfaceInstance: T) {
    this.interfaceInstance = interfaceInstance;
  }

  public getInstance(): T {
    return { ...this.DefaultInterface, ...this.interfaceInstance };
  }
}

import EntityParameterSettings from "@/interfaces/Parameters/EntityParameterSettings";
import ParameterSettings from "@/interfaces/Parameters/ParameterSettings";
import Vue from "vue";

const END_POINT = "api/customerPortal/v1/parameterSettings";

export async function getParameterSettings(): Promise<ParameterSettings> {
  let parameters = {} as ParameterSettings;

  await Vue.axios
    .get(END_POINT)
    .then((response) => (parameters = response.data));

  return parameters;
}

export async function getEntityParameterSettings(): Promise<EntityParameterSettings> {
  let parameters = {} as EntityParameterSettings;

  await Vue.axios
    .get(`${END_POINT}/entity`)
    .then((response) => (parameters = response.data));

  return parameters;
}

import ComboboxObject from "@/interfaces/Base/Combobox/ComboboxObject";
import BaseEnumerationMember from "./BaseEnumerationMember";

export abstract class BaseEnumeration<Type> {
  abstract GetContents(): BaseEnumerationMember<Type>[];

  public GetComboboxObjects(): ComboboxObject[] {
    return this.GetContents().map(
      (x) => ({ text: x.description, value: x.value } as ComboboxObject)
    );
  }

  public GetDescriptionFromValue(value: Type): string {
    return this.GetContents().find((x) => x.value === value)?.description ?? "";
  }
}

import SecuritySettingsBase from "./SecuritySettingsBase";

export default class GeneralSecuritySettings extends SecuritySettingsBase {
  private static instance: GeneralSecuritySettings;

  private constructor() {
    super();
  }

  public static getInstance(): GeneralSecuritySettings {
    if (!GeneralSecuritySettings.instance) {
      GeneralSecuritySettings.instance = new GeneralSecuritySettings();
    }

    return GeneralSecuritySettings.instance;
  }

  public get viewOnly(): boolean {
    return super.isViewOnly;
  }

  public get manager(): boolean {
    return super.isManager;
  }

  public get managerAccountSelected(): boolean {
    return super.isManagerAccountSelected;
  }
}


import { Vue, Component, PropSync } from "vue-property-decorator";
import WebLoginAccount from "@/interfaces/WebLogin/WebLoginAccount";
import CardListDetailField from "@/components/Base/CardListDetail/CardListDetailField.vue";
import store from "@/store";
import { buildAddress } from "@/helpers/Account/AddressHelpers";

@Component({ components: { CardListDetailField } })
export default class AccountDetailCardCard extends Vue {
  @PropSync("selectedAccount")
  selectedAccountSync!: WebLoginAccount;

  removeDialog = false;
  removing = false;

  get forceSelectAccount(): boolean {
    return !store.getters.webLoginModule.selectedAccount;
  }

  get canRemoveAcoount(): boolean {
    return (
      this.selectedAccountSync.id !==
      store.getters.webLoginModule.selectedAccount?.id
    );
  }

  returnClicked(): void {
    this.$emit("onReturn");
  }

  selectClicked(): void {
    this.$emit("onSelectAccount", this.selectedAccountSync);
  }

  removeAccount(): void {
    this.removeDialog = false;
    this.$emit("onRemoveAccount", this.selectedAccountSync);
  }

  private buildAddress(account: WebLoginAccount): string {
    if (!account.address1) return "";
    return buildAddress(
      account.address1,
      account.address2,
      account.city,
      account.state,
      account.zip,
      ""
    );
  }
}

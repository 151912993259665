import EmployeeCustomerAccess from "@/enumerations/Security/EmployeeCustomerAccess";
import EmployeeSecurity from "@/enumerations/Security/EmployeeSecurity";
import EntityParameterSettings from "@/interfaces/Parameters/EntityParameterSettings";
import ParameterSettings from "@/interfaces/Parameters/ParameterSettings";
import UserProfileBasedSecurity from "@/interfaces/Security/UserProfileBasedSecurity";
import WebLogin from "@/interfaces/WebLogin/WebLogin";
import store from "@/store";

export default abstract class SecuritySettingsBase {
  protected get parameters(): ParameterSettings {
    return store.getters.parametersModule.parameterSettings;
  }

  protected get entityParameters(): EntityParameterSettings {
    return store.getters.parametersModule.entityParameterSettings;
  }

  protected get useEntities(): boolean {
    return this.parameters.useEntities;
  }

  protected get webLogin(): WebLogin {
    return store.getters.webLoginModule.webLogin;
  }

  protected get userProfileBasedSecurity(): UserProfileBasedSecurity {
    return (
      store.getters.webLoginModule.userProfileBasedSecurity ??
      ({} as UserProfileBasedSecurity)
    );
  }

  /**
   * The user's view is View Only when they are an employee, their security is set to view only instead of user profile based, and a customer is selected that is not associated with the web login.
   */
  protected get isViewOnly(): boolean {
    return (
      this.webLogin.isEmployee &&
      this.webLogin.employeeSecurity === EmployeeSecurity.viewOnly &&
      store.getters.webLoginModule.selectedAccount?.id !== "" &&
      !store.getters.webLoginModule.selectedAccount?.isAccountLinkedToWebLogin
    );
  }

  /**
   * A user is a Manager when they are an employee and also have the customer access security setting on the web login set as field rep or user profile.
   * This is an account to manage their customers.
   */
  protected get isManager(): boolean {
    return (
      store.getters.webLoginModule.webLogin.isEmployee &&
      (store.getters.webLoginModule.webLogin.employeeCustomerAccess ===
        EmployeeCustomerAccess.fieldRep ||
        store.getters.webLoginModule.webLogin.employeeCustomerAccess ===
          EmployeeCustomerAccess.userProfile)
    );
  }

  /**
   * A Manager Account is selected when the user is a manager and does not have one of their customers selected.
   */
  protected get isManagerAccountSelected(): boolean {
    return this.isManager && !store.getters.webLoginModule.selectedAccount?.id;
  }

  protected checkUserProfileBasedSecurity(
    currentSecurityLevel: number,
    minimumSecurityLevel: number
  ): boolean {
    return this.webLogin.isEmployee &&
      this.webLogin.employeeSecurity === EmployeeSecurity.userProfile &&
      !store.getters.webLoginModule.selectedAccount?.isAccountLinkedToWebLogin
      ? currentSecurityLevel >= minimumSecurityLevel
      : true;
  }
}

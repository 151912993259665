
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import WebLoginAccount from "@/interfaces/WebLogin/WebLoginAccount";
import AccountPasswordResetForm from "@/components/Account/AccountPasswordResetForm.vue";
import CreateAccountForm from "@/components/Account/CreateAccountForm.vue";
import SelectAccountCard from "@/components/WebLogin/AccountSelection/SelectAccountCard.vue";
import SearchAccountCard from "@/components/WebLogin/AccountSelection/SearchAccountCard.vue";
import AccountDetailCard from "@/components/WebLogin/AccountSelection/AccountDetailCard.vue";
import {
  getAccounts,
  removeAccount,
} from "@/repositories/Web/WebMapRepository";
import { setAccountLastLogin } from "@/repositories/Web/WebLoginRepository";
import store from "@/store";
import EmployeeCustomerAccess from "@/enumerations/Security/EmployeeCustomerAccess";

@Component({
  components: {
    SelectAccountCard,
    SearchAccountCard,
    AccountDetailCard,
    AccountPasswordResetForm,
    CreateAccountForm,
  },
})
export default class AccountSelectionModal extends Vue {
  @Prop({ required: true })
  show!: boolean;

  customerId = "";
  customerName = "";
  layer = this.canSearch ? "search" : "select";
  loading = false;
  accounts = [] as WebLoginAccount[];
  selectedAccount = {} as WebLoginAccount;
  showDialog = false;
  previousLayer = "";

  @Watch("show", { immediate: true })
  async showChanged(newValue: boolean): Promise<void> {
    this.layer = this.canSearch ? "search" : "select";
    if (newValue && !this.canSearch) {
      this.onSearch();
    } else {
      this.showDialog = this.show;
    }
  }

  changePassword(): void {
    this.previousLayer = this.layer;
    this.layer = "password";
  }

  addAccount(): void {
    this.previousLayer = this.layer;
    this.layer = "add";
  }

  get canSearch() {
    return (
      store.getters.webLoginModule.webLogin.isEmployee &&
      store.getters.webLoginModule.webLogin.employeeCustomerAccess ===
        EmployeeCustomerAccess.all
    );
  }

  cancelSelection(): void {
    this.customerId = "";
    this.customerName = "";
    this.layer = this.canSearch ? "search" : "select";
    this.$emit("select-account-modal-cancel");
  }

  cancelSettingsChange(): void {
    this.layer = this.previousLayer;
    this.previousLayer = "";
  }

  returnToResults(): void {
    this.layer = "select";
  }

  returnToSearch(): void {
    this.layer = "search";
  }

  async onSearch(): Promise<void> {
    this.loading = true;
    this.accounts = await getAccounts(
      this.customerId,
      this.customerName,
      this.canSearch
    );

    if (this.accounts.length === 1 && !this.canSearch) {
      this.selectAccount(this.accounts[0]);
    } else {
      this.showDialog = this.show;
    }

    this.layer = "select";
    this.loading = false;
  }

  async removeAccount(account: WebLoginAccount): Promise<void> {
    const index = this.accounts.indexOf(account);
    this.accounts.splice(index, 1);
    await removeAccount(account.id, account.entityId);
    this.layer = "select";
    if (
      store.getters.webLoginModule.selectedAccount !== undefined &&
      this.accounts.length === 1
    ) {
      const selectedAccount = {
        ...store.getters.webLoginModule.selectedAccount,
      } as WebLoginAccount;
      selectedAccount.canSwitchAccounts = false;
      store.dispatch.webLoginModule.setSelectedAccount(selectedAccount);
    }
  }

  async selectAccount(account: WebLoginAccount): Promise<void> {
    account.canSwitchAccounts =
      store.getters.webLoginModule.webLogin.isEmployee ||
      this.accounts.length !== 1;
    store.dispatch.webLoginModule.setSelectedAccount(account);
    if (account.isAccountLinkedToWebLogin) {
      await setAccountLastLogin(account.id);
    }
    this.cancelSelection();
  }

  viewAccount(account: WebLoginAccount): void {
    this.selectedAccount = account;
    this.layer = "detail";
  }
}


import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class CardListDetailField extends Vue {
  @Prop({ required: true })
  label!: string;

  @Prop({ required: true })
  value!: string | number | null;

  @Prop({ required: false, default: false })
  loading!: boolean;

  @Prop({ required: false, default: 75 })
  skeletonLoaderWidth!: number;

  @Prop({ required: false, default: "" })
  textColor!: string;
}

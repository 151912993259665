
import { Vue, Component } from "vue-property-decorator";
import { forgotUsername } from "@/repositories/Web/WebLoginRepository";

@Component
export default class ForgotUsername extends Vue {
  isLoading = false;
  isValid = true;
  email = "";

  private async retrieve(): Promise<void> {
    const form = this.$refs.form as HTMLFormElement;
    form?.validate();

    if (!this.isValid) return;
    await forgotUsername(this.email);
    this.$emit("onRetrieved");
  }
}

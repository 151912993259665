
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class AccountPasswordResetFormRequirementIndicator extends Vue {
  @Prop({ required: true })
  requirementText!: string;

  @Prop({ required: true })
  isValid!: boolean;

  get requirementStatusTextClass() {
    return this.isValid ? "green--text" : "red--text";
  }
}

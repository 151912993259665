
import { Vue, Component } from "vue-property-decorator";
import store from "@/store";
import CreateAccountForm from "@/components/Account/CreateAccountForm.vue";
import LoginForm from "@/components/Account/LoginForm.vue";
import ForgotUsername from "@/components/Account/ForgotUsername.vue";
import ForgotPassword from "@/components/Account/ForgotPassword.vue";
import ParameterSettings from "@/interfaces/Parameters/ParameterSettings";
import LandingViewBase from "@/components/Base/StandardBaseView/LandingBaseView.vue";
import MarketingCarousel from "@/components/ContentManagementSystem/MarketingCarousel.vue";

@Component({
  components: {
    LandingViewBase,
    LoginForm,
    CreateAccountForm,
    ForgotPassword,
    ForgotUsername,
    MarketingCarousel,
  },
})
export default class Login extends Vue {
  store = this.$store.direct;
  isInstalledAsPwa = false;
  layer = "login";
  tab = 0;
  success = false;
  resetSuccess = false;

  get isUsingContentManagementSystem(): boolean {
    return (
      store.getters.configurationModule.configurationContents
        .contentManagementSystemBaseUrl != ""
    );
  }

  get parameterSettings(): ParameterSettings {
    return this.store.getters.parametersModule.parameterSettings;
  }

  private moveTo(toLayer: string): void {
    this.success = false;
    this.tab = 0;
    this.layer = toLayer;
  }

  private activeTabColor(currentTab: number): string {
    return this.tab !== currentTab ? "background-color: #E5F3FE;" : "";
  }

  mounted(): void {
    if (this.$route.query.resetSuccess) {
      this.resetSuccess = this.$route.query.resetSuccess === "true";
    }
  }

  created() {
    this.isInstalledAsPwa = window.matchMedia(
      "(display-mode: standalone)"
    ).matches;
  }

  private onSuccess(): void {
    this.moveTo("login");
    this.tab = 0;
    this.success = true;
  }
}

import { RouteConfig } from "vue-router";
import CommoditySecuritySettings from "@/helpers/Security/CommoditySecuritySettings";
const ViewContracts = () =>
  import(
    /* webpackChunkName: "CommodityViewContracts" */ "@/views/Commodity/ViewContracts.vue"
  );
const ViewBids = () =>
  import(
    /* webpackChunkName: "CommodityViewBids" */ "@/views/Commodity/ViewBids.vue"
  );
const ViewTickets = () =>
  import(
    /* webpackChunkName: "CommodityViewTickets" */ "@/views/Commodity/ViewTickets.vue"
  );
const ViewActivity = () =>
  import(
    /* webpackChunkName: "CommodityViewActivity" */ "@/views/Commodity/ViewActivity.vue"
  );
const ViewBalances = () =>
  import(
    /* webpackChunkName: "CommodityViewBalances" */ "@/views/Commodity/ViewBalances.vue"
  );
const ViewGrainBank = () =>
  import(
    /* webpackChunkName: "CommodityViewGrainBank" */ "@/views/Commodity/ViewGrainBank.vue"
  );
const ViewProofOfYield = () =>
  import(
    /* webpackChunkName: "CommodityViewProofOfYield" */ "@/views/Commodity/ViewProofOfYield.vue"
  );

const commoditySecuritySettings = CommoditySecuritySettings.getInstance();

const commodityMenuRoutes: RouteConfig[] = [
  {
    path: "/balances/view",
    name: "view-commodity-balances",
    component: ViewBalances,
    meta: {
      displayName: "Balances",
    },
    beforeEnter: (_to, _from, next) =>
      commoditySecuritySettings.showBalancesPage ? next() : next("/"),
  },
  {
    path: "/contracts/view",
    name: "view-commodity-contracts",
    component: ViewContracts,
    meta: {
      displayName: "Contracts",
    },
    beforeEnter: (_to, _from, next) =>
      commoditySecuritySettings.showContractsPage ? next() : next("/"),
  },
  {
    path: "/activity/:type",
    name: "view-commodity-activity",
    component: ViewActivity,
    meta: {
      displayName: "Activity",
    },
    beforeEnter: (_to, _from, next) =>
      commoditySecuritySettings.showActivityPage ? next() : next("/"),
  },
  {
    path: "/bids/view",
    name: "view-commodity-bids",
    component: ViewBids,
    meta: {
      displayName: "Cash Bids",
      guid: "159184FE-D4D7-42D2-1E1C-10228B3B2169",
    },
    beforeEnter: (_to, _from, next) =>
      commoditySecuritySettings.showCashBidsPage ? next() : next("/"),
  },
  {
    path: "/tickets/view",
    name: "view-commodity-tickets",
    component: ViewTickets,
    meta: {
      displayName: "Tickets",
    },
    beforeEnter: (_to, _from, next) =>
      commoditySecuritySettings.showTicketsPage ? next() : next("/"),
  },
  {
    path: "/grain-bank/view",
    name: "view-commodity-grain-bank",
    component: ViewGrainBank,
    meta: {
      displayName: "Grain Bank",
    },
    beforeEnter: (_to, _from, next) =>
      commoditySecuritySettings.showGrainBankPage ? next() : next("/"),
  },
  {
    path: "/proof-of-yield/view",
    name: "view-commodity-proof-of-yield",
    component: ViewProofOfYield,
    meta: {
      displayName: "Proof of Yield",
    },
    beforeEnter: (_to, _from, next) =>
      commoditySecuritySettings.showProofOfYieldPage ? next() : next("/"),
  },
];

export default commodityMenuRoutes;

import { AxiosRequestConfig, AxiosResponse } from "axios";
import Vue from "vue";
import qs from "qs";
import RepositoryRequestResponse from "@/interfaces/Repository/Requests/RepositoryRequestResponse";

export default class RepositoryRequest {
  private endPoint = "";
  private customerId = "";
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private params? = null as any;

  public async GetData<T>(): Promise<T> {
    const response = await this.PerformGetRequest();
    return response.data as T;
  }

  public async Get(): Promise<RepositoryRequestResponse> {
    return (await this.PerformGetRequest()) as RepositoryRequestResponse;
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public async Put(body: any): Promise<RepositoryRequestResponse> {
    const response = await Vue.axios.put(this.endPoint, body);
    return { ...response } as RepositoryRequestResponse;
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public async Post(body: any): Promise<RepositoryRequestResponse> {
    const response = await Vue.axios.post(this.endPoint, body);
    return { ...response } as RepositoryRequestResponse;
  }

  public async Delete(): Promise<RepositoryRequestResponse> {
    const response = await Vue.axios.delete(this.endPoint);
    return { ...response } as RepositoryRequestResponse;
  }

  public WithUrl(url: string): RepositoryRequest {
    this.endPoint = url;
    return this;
  }

  /** Filter parameters for GET requests. */
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public WithFilter(filter: any): RepositoryRequest {
    this.params = filter;
    return this;
  }

  /** Sets the customer ID on the request header instead of defaulting the customer Id from the selected account. */
  public WithCustomer(customerId: string): RepositoryRequest {
    this.customerId = customerId;
    return this;
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private async PerformGetRequest(): Promise<AxiosResponse<any>> {
    return await Vue.axios.get(this.endPoint, this.GetRequestConfiguration());
  }

  private GetRequestConfiguration(): AxiosRequestConfig {
    return {
      paramsSerializer: (parametersObject) =>
        qs.stringify(parametersObject, { arrayFormat: "repeat" }),
      params: this.params,
      headers: this.GetRequestHeaders(),
    } as AxiosRequestConfig;
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private GetRequestHeaders(): any {
    if (this.customerId === "") {
      return null;
    } else {
      return {
        "Customer-Id": this.customerId,
      };
    }
  }
}

import Vue from "vue";
import store from "@/store/index";
import LastLogin from "@/interfaces/WebLogin/LastLogin";
import WebLoginAuthentication from "@/interfaces/WebLogin/WebLoginAuthentication";
import WebLoginAuthenticationTokens from "@/interfaces/WebLogin/WebLoginAuthenticationTokens";
import Alert from "@/interfaces/Alerts/Alert";
import { AlertType } from "@/enumerations/Alerts/AlertType";
import RepositoryResponse from "@/interfaces/Repository/RepositoryResponse";
import RepositoryRequestResponse from "@/interfaces/Repository/Requests/RepositoryRequestResponse";
import RepositoryRequest from "@/helpers/Repositories/RepositoryRequest";

const END_POINT = "api/customerPortal/v1/webLogin";

export async function login(
  username: string,
  password: string,
  keepLoggedIn: boolean
): Promise<WebLoginAuthentication> {
  let webLogin = {} as WebLoginAuthentication;

  await Vue.axios
    .post(
      `${END_POINT}/login`,
      {
        keepLoggedIn: keepLoggedIn,
      },
      {
        auth: {
          username: username,
          password: password,
        },
      }
    )
    .then((response) => (webLogin = response.data));

  return webLogin;
}

export async function getLogins(): Promise<LastLogin[]> {
  let lastLogins = [] as LastLogin[];

  await Vue.axios
    .get(`${END_POINT}/accountAudit`)
    .then((response) => (lastLogins = response.data));

  return lastLogins;
}

export async function setAccountLastLogin(customerId: string): Promise<void> {
  await Vue.axios.put(`${END_POINT}/accountAudit`, {
    customerId: customerId,
  });
}

export async function resetPasswordWithToken(
  token: string,
  username: string,
  newPassword: string
): Promise<RepositoryResponse> {
  return await Vue.axios.put(`${END_POINT}/resetPasswordToken`, {
    resetToken: token,
    loginId: username,
    newPassword: newPassword,
  });
}

export async function resetPassword(
  jsonWebToken: string,
  oldPassword: string,
  newPassword: string
): Promise<WebLoginAuthentication> {
  return Vue.axios
    .post(`${END_POINT}/resetPassword`, {
      jsonWebToken: jsonWebToken,
      oldPassword: oldPassword,
      newPassword: newPassword,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        //json web token expired before user submitted password reset
        //tokens are not allowed to be refreshed when a password reset is required
        store.dispatch.alertsModule.setAlert({
          headerMessage: "Session timeout",
          bodyMessage: "Your password was not updated",
          type: AlertType.Error,
        } as Alert);
      }
      return undefined;
    });
}

export async function getRefreshToken(
  jsonWebToken: string,
  refreshToken: string
): Promise<WebLoginAuthenticationTokens> {
  let tokens = {} as WebLoginAuthenticationTokens;

  await Vue.axios
    .post(`${END_POINT}/refreshToken`, {
      jsonWebToken: jsonWebToken,
      refreshToken: refreshToken,
    })
    .then((response) => {
      tokens = response.data;
    });

  return tokens;
}

export async function forgotPassword(
  emailaddress: string,
  username: string
): Promise<void> {
  await Vue.axios.post(`${END_POINT}/forgotPassword`, {
    email: emailaddress,
    username: username,
  });
}

export async function forgotUsername(emailaddress: string): Promise<void> {
  await Vue.axios.post(
    `${END_POINT}/forgotUsername`,
    '"' + emailaddress + '"',
    { headers: { "Content-type": "application/json" } }
  );
}

export async function verifyCustomerByReceipt(
  customerId: string,
  storeNumber: string,
  invoiceNumber: string
): Promise<RepositoryRequestResponse> {
  const getRequestParameters = {
    customerId: customerId,
    storeNumber: storeNumber,
    invoiceNumber: invoiceNumber,
  };

  return await new RepositoryRequest()
    .WithUrl(`${END_POINT}/lookup`)
    .WithFilter(getRequestParameters)
    .Get();
}

export async function createWebLogin(
  username: string,
  displayName: string,
  emailaddress: string,
  storeNumber: string,
  accountNumber: string,
  invoiceNumber: string
): Promise<RepositoryRequestResponse> {
  return await new RepositoryRequest().WithUrl(END_POINT).Post({
    username: username,
    displayName: displayName,
    email: emailaddress,
    locationId: storeNumber,
    accountId: accountNumber,
    invoiceNumber: invoiceNumber,
  });
}

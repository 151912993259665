const ViewOrders = () =>
  import(
    /* webpackChunkName: "OrdersViewOrders" */ "@/views/Orders/ViewOrders.vue"
  );
const CreateOrders = () =>
  import(
    /* webpackChunkName: "OrdersCreateOrders" */ "@/views/Orders/ViewCreateOrder.vue"
  );

import { RouteConfig } from "vue-router";
import OrdersSecuritySettings from "@/helpers/Security/OrdersSecuritySettings";

const ordersSecuritySettings = OrdersSecuritySettings.getInstance();

const ordersMenuRoutes: RouteConfig[] = [
  {
    path: "/orders/create",
    name: "create-order",
    component: CreateOrders,
    meta: {
      displayName: "Create an Order",
    },
    beforeEnter: (_to, _from, next) =>
      ordersSecuritySettings.showCreateOrderPage ? next() : next("/"),
  },
  {
    path: "/orders/view",
    name: "view-orders",
    component: ViewOrders,
    meta: {
      displayName: "View Orders",
    },
    beforeEnter: (_to, _from, next) =>
      ordersSecuritySettings.showViewOrdersPage ? next() : next("/"),
  },
];

export default ordersMenuRoutes;

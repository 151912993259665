import AccountPasswordResetRequirements from "@/interfaces/Account/PasswordReset/AccountPasswordResetRequirements";

export const PasswordValidateRegex =
  /(?=^.{8,}$)((?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;

export function validatePasswordMeetsRequirements(
  password: string
): AccountPasswordResetRequirements {
  const upperAndLowerCaseLettersRegex = /(?=.*[A-Z])(?=.*[a-z]).*$/;
  const specialCharactersRegex = /\W+/;
  const requirements = {
    length: false,
    usesSpecialCharacter: false,
    usesUpperAndLowerCaseLetters: false,
  } as AccountPasswordResetRequirements;

  if (password.length >= 8) requirements.length = true;
  if (upperAndLowerCaseLettersRegex.test(password))
    requirements.usesUpperAndLowerCaseLetters = true;
  if (specialCharactersRegex.test(password))
    requirements.usesSpecialCharacter = true;

  return requirements;
}

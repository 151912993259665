import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import ordersMenuRoutes from "@/router/orders";
import purchasesMenuRoutes from "@/router/purchases";
import accountMenuRoutes from "@/router/account";
import commodityMenuRoutes from "@/router/commodity";
import contractsMenuRoutes from "@/router/contracts";
import paymentMenuRoutes from "@/router/payments";
import managerMenuRoutes from "@/router/manager";
import Login from "@/views/Login/Login.vue";

const ContactUs = () =>
  import(
    /* webpackChunkName: "ContactUsPage" */ "@/views/Contact/ContactUs.vue"
  );
const ResetPassword = () =>
  import(
    /* webpackChunkName: "ResetPasswordPage" */ "@/views/Login/ResetPassword.vue"
  );
const HomePage = () =>
  import(/* webpackChunkName: "HomePage" */ "@/views/Home/HomePage.vue");
const InstallAppHowToPage = () =>
  import(
    /* webpackChunkName: "InstallAppHowToPage" */ "@/views/ProgressiveWebApp/InstallAppHowToPage.vue"
  );
const LicensesPage = () =>
  import(
    /* webpackChunkName: "LicensesPage" */ "@/views/Licenses/ViewLicenses.vue"
  );
const FieldAlyticsSSOPage = () =>
  import(
    /* webpackChunkName: "FieldAlyticsSSOPage" */ "@/views/FieldAlytics/FieldAlyticsSSOPage.vue"
  );
import store from "@/store";

Vue.use(VueRouter);

let routes: RouteConfig[] = [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      skipAuth: true,
    },
  },
  {
    path: "/resetPassword",
    name: "resetPassword",
    component: ResetPassword,
    meta: {
      skipAuth: true,
    },
  },
  {
    path: "/home",
    name: "home",
    component: HomePage,
    meta: {
      guid: "d75a374c-d46a-445b-a7a0-a740d1e048e7",
    },
  },
  {
    path: "/",
    name: "",
    component: HomePage,
    meta: {
      guid: "d75a374c-d46a-445b-a7a0-a740d1e048e7",
    },
  },
  {
    path: "/install-app-how-to",
    name: "install-app-how-to",
    component: InstallAppHowToPage,
    meta: {
      skipAuth: true,
    },
  },
  {
    path: "/contact-us",
    name: "contact-us",
    component: ContactUs,
  },
  {
    path: "/licenses/view",
    name: "view-licenses",
    component: LicensesPage,
    meta: {
      displayName: "View Licenses",
    },
    beforeEnter: (_to, _from, next) =>
      store.getters.parametersModule.parameterSettings.showLicenses
        ? next()
        : next("/"),
  },
  {
    path: "/fieldAlytics/sso",
    name: "field-alytics-sso",
    component: FieldAlyticsSSOPage,
    meta: {
      displayName: "",
    },
    beforeEnter: (_to, _from, next) =>
      store.getters.parametersModule.parameterSettings.showFieldAlytics
        ? next()
        : next("/"),
  },
];

routes = routes.concat(accountMenuRoutes);
routes = routes.concat(ordersMenuRoutes);
routes = routes.concat(purchasesMenuRoutes);
routes = routes.concat(commodityMenuRoutes);
routes = routes.concat(contractsMenuRoutes);
routes = routes.concat(paymentMenuRoutes);
routes = routes.concat(managerMenuRoutes);

const router = new VueRouter({
  routes,
});

router.beforeEach(async (to, from, next) => {
  //clear any alert that was on the current page
  store.dispatch.alertsModule.setAlert(undefined);

  if (!store.getters.parametersModule.isLoaded) {
    await store.dispatch.parametersModule.setParameterSettings();
  }

  //restore state from local storage in case user refreshed page
  if (store.getters.webLoginModule.tokens === undefined) {
    store.dispatch.webLoginModule.restoreTokens();
  }
  if (store.getters.webLoginModule.userProfileBasedSecurity === undefined) {
    store.dispatch.webLoginModule.restoreUserBasedSecurity();
  }

  if (
    store.getters.webLoginModule.tokens !== undefined &&
    store.getters.parametersModule.parameterSettings.useEntities &&
    !store.getters.parametersModule.isEntityParametersLoaded &&
    store.getters.webLoginModule.selectedAccount?.entityId
  ) {
    await store.dispatch.parametersModule.setEntityParameterSettings();
  }

  if (
    store.getters.webLoginModule.tokens === undefined &&
    (to.matched.some((record) => !record.meta.skipAuth) || to.path === "/")
  ) {
    next({
      path: "/login",
    });
  } else if (
    store.getters.webLoginModule.tokens !== undefined &&
    to.path === "/"
  ) {
    next({
      path: "/home",
    });
  } else if (
    store.getters.webLoginModule.tokens === undefined &&
    to.name === "resetPassword" &&
    to.query.token
  ) {
    next();
  } else if (
    store.getters.webLoginModule.webLogin?.forcePasswordChange &&
    to.name !== "resetPassword"
  ) {
    //attempting to navigate away from reset password page when password reset is needed
    next({
      path: "/resetPassword",
    });
  } else if (
    !store.getters.webLoginModule.webLogin?.forcePasswordChange &&
    to.name === "resetPassword"
  ) {
    //attempting to navigate to reset password page when password reset is not needed
    next({
      path: from.path,
    });
  } else {
    next();
  }
});

export default router;

const ViewPurchases = () =>
  import(
    /* webpackChunkName: "PurchasesViewPurchases" */ "@/views/Purchases/ViewPurchases.vue"
  );
const ViewCreditActivity = () =>
  import(
    /* webpackChunkName: "PurchasesViewCreditActivity" */ "@/views/Purchases/ViewCreditActivity.vue"
  );
const ViewTransactions = () =>
  import(
    /* webpackChunkName: "PurchasesViewTransactions" */ "@/views/Purchases/ViewTransactions.vue"
  );
const ViewStatements = () =>
  import(
    /* webpackChunkName: "PurchasesViewStatements" */ "@/views/Purchases/ViewStatements.vue"
  );
const ViewPurchaseSummary = () =>
  import(
    /* webpackChunkName: "PurchasesViewPurchaseSummary" */ "@/views/Purchases/ViewPurchaseSummary.vue"
  );

import PurchasesSecuritySettings from "@/helpers/Security/PurchasesSecuritySettings";
import { RouteConfig } from "vue-router";

const purchaseSecuritySettings = PurchasesSecuritySettings.getInstance();

const purchasesMenuRoutes: RouteConfig[] = [
  {
    path: "/credit-activity/view",
    name: "view-credit-activity",
    component: ViewCreditActivity,
    meta: {
      displayName: "View Credit Activity",
    },
    beforeEnter: (_to, _from, next) =>
      purchaseSecuritySettings.showViewCreditActivityPage ? next() : next("/"),
  },
  {
    path: "/purchases/view",
    name: "view-purchases",
    component: ViewPurchases,
    meta: {
      displayName: "View Purchases",
    },
    beforeEnter: (_to, _from, next) =>
      purchaseSecuritySettings.showViewPurchasesPage ? next() : next("/"),
  },
  {
    path: "/transactions/view",
    name: "view-transactions",
    component: ViewTransactions,
    meta: {
      displayName: "View Transactions",
    },
    beforeEnter: (_to, _from, next) =>
      purchaseSecuritySettings.showViewTransactionsPage ? next() : next("/"),
  },
  {
    path: "/statements/view",
    name: "view-statements",
    component: ViewStatements,
    meta: {
      displayName: "View Statements",
    },
    beforeEnter: (_to, _from, next) =>
      purchaseSecuritySettings.showViewStatementsPage ? next() : next("/"),
  },
  {
    path: "/purchase-summary/view",
    name: "view-purchase-summary",
    component: ViewPurchaseSummary,
    meta: {
      displayName: "View Purchase Summary",
    },
    beforeEnter: (_to, _from, next) =>
      purchaseSecuritySettings.showViewPurchaseSummaryPage ? next() : next("/"),
  },
];

export default purchasesMenuRoutes;

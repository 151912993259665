import InterfaceFactory from "@/helpers/Interfaces/InterfaceFactory";
import WebLoginAccount from "@/interfaces/WebLogin/WebLoginAccount";

export default class WebLoginAccountFactory extends InterfaceFactory<WebLoginAccount> {
  DefaultInterface = {
    id: "",
    name: "",
    entityId: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    apEmail: "",
    creditLimt: 0,
    currentBalance: 0,
    customerType: "",
    isAccountLinkedToWebLogin: false,
    canSwitchAccounts: false,
  };
}

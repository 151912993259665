import ViewOnlyFullAccessSecurity from "@/enumerations/Security/ViewOnlyFullAccessSecurity";
import YesNoSecurity from "@/enumerations/Security/YesNoSecurity";
import SecuritySettingsBase from "./SecuritySettingsBase";

export default class AccountSecuritySettings extends SecuritySettingsBase {
  private static instance: AccountSecuritySettings;

  private constructor() {
    super();
  }

  public static getInstance(): AccountSecuritySettings {
    if (!AccountSecuritySettings.instance) {
      AccountSecuritySettings.instance = new AccountSecuritySettings();
    }

    return AccountSecuritySettings.instance;
  }

  private get showAccount(): boolean {
    return (
      !this.isManagerAccountSelected &&
      this.checkUserProfileBasedSecurity(
        this.userProfileBasedSecurity.account,
        YesNoSecurity.yes
      )
    );
  }

  public get showAccountMenu(): boolean {
    return (
      this.showAccount &&
      (this.showAccountSummaryPage ||
        this.showAddressesPage ||
        this.showBuyersPage ||
        this.showLoginHistoryPage ||
        this.showProfilePage)
    );
  }

  public get showAccountSummaryPage(): boolean {
    return (
      this.showAccount &&
      this.checkUserProfileBasedSecurity(
        this.userProfileBasedSecurity.viewAccountSummary,
        YesNoSecurity.yes
      )
    );
  }

  public get showAddressesPage(): boolean {
    return (
      this.parameters.showAddressBook &&
      this.showAccount &&
      this.checkUserProfileBasedSecurity(
        this.userProfileBasedSecurity.addressBook,
        ViewOnlyFullAccessSecurity.viewOnly
      )
    );
  }

  public get editAddressesPage(): boolean {
    return (
      this.showAddressesPage &&
      !this.isViewOnly &&
      this.checkUserProfileBasedSecurity(
        this.userProfileBasedSecurity.addressBook,
        ViewOnlyFullAccessSecurity.fullAccess
      )
    );
  }

  public get showBuyersPage(): boolean {
    return (
      this.parameters.showBuyers &&
      this.showAccount &&
      this.checkUserProfileBasedSecurity(
        this.userProfileBasedSecurity.buyers,
        ViewOnlyFullAccessSecurity.viewOnly
      )
    );
  }

  public get editBuyersPage(): boolean {
    return (
      this.showBuyersPage &&
      !this.isViewOnly &&
      this.checkUserProfileBasedSecurity(
        this.userProfileBasedSecurity.buyers,
        ViewOnlyFullAccessSecurity.fullAccess
      )
    );
  }

  public get showLoginHistoryPage(): boolean {
    return (
      this.showAccount &&
      this.checkUserProfileBasedSecurity(
        this.userProfileBasedSecurity.viewLoginHistory,
        YesNoSecurity.yes
      )
    );
  }

  public get showProfilePage(): boolean {
    return (
      this.showAccount &&
      this.checkUserProfileBasedSecurity(
        this.userProfileBasedSecurity.accountProfile,
        ViewOnlyFullAccessSecurity.viewOnly
      )
    );
  }

  public get editProfilePage(): boolean {
    return (
      this.showProfilePage &&
      !this.isViewOnly &&
      this.checkUserProfileBasedSecurity(
        this.userProfileBasedSecurity.accountProfile,
        ViewOnlyFullAccessSecurity.fullAccess
      )
    );
  }
}

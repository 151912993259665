import Alert from "@/interfaces/Alerts/Alert";

const SET_ALERT = "SET_ALERT";

export interface AlertState {
  alert: Alert | undefined;
}
export default {
  namespaced: true as const,
  state: {
    alert: undefined,
  } as AlertState,
  getters: {
    alert: (state: AlertState) => state.alert,
  },
  mutations: {
    [SET_ALERT](state: AlertState, newAlert: Alert | undefined) {
      state.alert = newAlert ? { ...newAlert } : undefined;
    },
  },
  actions: {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    setAlert(context: any, payload: Alert | undefined) {
      context.commit(SET_ALERT, payload);
    },
  },
};

import Notification from "@/interfaces/Notifications/Notification";

const SET_NOTIFICATION = "SET_NOTIFICATION";

export interface NotificationState {
  notification: Notification | undefined;
}
export default {
  namespaced: true as const,
  state: {
    notification: undefined,
  } as NotificationState,
  getters: {
    notification: (state: NotificationState) => state.notification,
  },
  mutations: {
    [SET_NOTIFICATION](
      state: NotificationState,
      newNotification: Notification | undefined
    ) {
      state.notification = newNotification ? { ...newNotification } : undefined;
    },
  },
  actions: {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    setNotification(context: any, payload: Notification | undefined) {
      context.commit(SET_NOTIFICATION, payload);
    },
  },
};

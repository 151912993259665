import EntityParameterSettings from "@/interfaces/Parameters/EntityParameterSettings";
import ParameterSettings from "@/interfaces/Parameters/ParameterSettings";
import {
  getEntityParameterSettings,
  getParameterSettings,
} from "@/repositories/Parameters/ParameterRepository";

const SET_PARAMETER_SETTINGS = "SET_PARAMETER_SETTINGS";
const SET_PARAMETER_IS_LOADING = "SET_PARAMETER_IS_LOADING";
const SET_PARAMETER_IS_LOADED = "SET_PARAMETER_IS_LOADED";
const SET_ENTITY_PARAMETER_SETTINGS = "SET_ENTITY_PARAMETER_SETTINGS";
const SET_ENTITY_PARAMETER_IS_LOADING = "SET_ENTITY_PARAMETER_IS_LOADING";
const SET_ENTITY_PARAMETER_IS_LOADED = "SET_ENTITY_PARAMETER_IS_LOADED";

export interface ParameterSettingsState {
  isLoading: boolean;
  isLoaded: boolean;
  parameterSettings: ParameterSettings;
  entityParameterSettings: EntityParameterSettings;
  entityParametersIsLoading: boolean;
  entityParametersIsLoaded: boolean;
}
export default {
  namespaced: true as const,
  state: {
    parameterSettings: {} as ParameterSettings,
    isLoaded: false,
    isLoading: false,
    entityParameterSettings: {} as EntityParameterSettings,
    entityParametersIsLoaded: false,
    entityParametersIsLoading: false,
  } as ParameterSettingsState,
  mutations: {
    [SET_PARAMETER_SETTINGS](
      state: ParameterSettingsState,
      newSettings: ParameterSettings
    ) {
      state.parameterSettings = { ...newSettings };
    },
    [SET_PARAMETER_IS_LOADING](
      state: ParameterSettingsState,
      newIsLoading: boolean
    ) {
      state.isLoading = newIsLoading;
    },
    [SET_PARAMETER_IS_LOADED](
      state: ParameterSettingsState,
      newIsLoaded: boolean
    ) {
      state.isLoaded = newIsLoaded;
    },
    [SET_ENTITY_PARAMETER_SETTINGS](
      state: ParameterSettingsState,
      newSettings: EntityParameterSettings
    ) {
      state.entityParameterSettings = { ...newSettings };
    },
    [SET_ENTITY_PARAMETER_IS_LOADING](
      state: ParameterSettingsState,
      newIsLoading: boolean
    ) {
      state.entityParametersIsLoading = newIsLoading;
    },
    [SET_ENTITY_PARAMETER_IS_LOADED](
      state: ParameterSettingsState,
      newIsLoaded: boolean
    ) {
      state.entityParametersIsLoaded = newIsLoaded;
    },
  },
  actions: {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    async setParameterSettings(context: any) {
      context.commit(SET_PARAMETER_IS_LOADING, true);

      await getParameterSettings()
        .then((response) => {
          context.commit(SET_PARAMETER_SETTINGS, response);
          context.commit(SET_PARAMETER_IS_LOADED, true);
        })
        .finally(() => {
          context.commit(SET_PARAMETER_IS_LOADING, false);
        });
    },
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    async setEntityParameterSettings(context: any) {
      context.commit(SET_ENTITY_PARAMETER_IS_LOADING, true);

      await getEntityParameterSettings()
        .then((response) => {
          context.commit(SET_ENTITY_PARAMETER_SETTINGS, response);
          context.commit(SET_ENTITY_PARAMETER_IS_LOADED, true);
        })
        .finally(() => {
          context.commit(SET_ENTITY_PARAMETER_IS_LOADING, false);
        });
    },
  },
  getters: {
    parameterSettings: (state: ParameterSettingsState) =>
      state.parameterSettings,
    entityParameterSettings: (state: ParameterSettingsState) =>
      state.entityParameterSettings,
    isLoaded: (state: ParameterSettingsState) => state.isLoaded,
    isEntityParametersLoaded: (state: ParameterSettingsState) =>
      state.entityParametersIsLoaded,
    isLoading: (state: ParameterSettingsState) =>
      state.isLoading || state.entityParametersIsLoading,
  },
};

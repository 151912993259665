
import { Vue, Component, Prop } from "vue-property-decorator";
import {
  getCarouselItems,
  getLoginPageCarouselItems,
} from "@/repositories/ContentManagementSystem/ContentMangementSystemRepository";
import CarouselItem from "@/interfaces/ContentMangementSystem/CarouselItem";

@Component
export default class MarketingCarousel extends Vue {
  loading = false;
  carouselItems: CarouselItem[] = [];
  selectedCarouselItem: CarouselItem | null = null;
  showDialog = false;
  cmsError = false;

  @Prop({ required: false, default: false })
  isLoginPage!: boolean;

  async mounted() {
    this.loading = true;

    const getItems = this.isLoginPage
      ? getLoginPageCarouselItems
      : getCarouselItems;
    await getItems()
      .then((response) => {
        this.carouselItems = response;
      })
      .catch(() => {
        this.cmsError = true;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  private get hideCarousel(): boolean {
    return this.carouselItems.length == 0 && !this.loading && !this.cmsError;
  }

  textAreaClick(item: CarouselItem): void {
    if (item.externalLink) {
      window.open(item.externalLink, "_blank");
    } else if (item.dialogText) {
      this.selectedCarouselItem = item;
      this.showDialog = true;
    }
  }
}

import numeral from "numeral";

export function formatCurrency(value: string | number): string {
  return numeral(value).format("$0,0.00");
}

export function formatNumber(
  value: string | number,
  decimalPlaces?: number
): string {
  return numeral(value).format(`0,0.${"0".repeat(decimalPlaces ?? 2)}`);
}

export function formatNumberWithoutCommas(
  value: string | number,
  decimalPlaces?: number
): string {
  return numeral(value).format(`0.${"0".repeat(decimalPlaces ?? 2)}`);
}

export function formatNumberBlankWithNull(
  value: string | number | null,
  decimalPlaces?: number
): string {
  if (value === null || value === "") return "";
  return numeral(value).format(`0,0.${"0".repeat(decimalPlaces ?? 2)}`);
}

export function formatPercentage(
  value: string | number,
  decimalPlaces?: number
): string {
  return numeral(parseFloat(value.toString()) / 100).format(
    `0,0.${"0".repeat(decimalPlaces ?? 4)}%`
  );
}

export function formatPositiveNegativeNumber(value: string | number): string {
  return value < 0
    ? numeral(value).format("0,0.0000")
    : `+${numeral(value).format("0,0.0000")}`;
}

export function mask(value: string, numberOfPlacesOmitted?: number): string {
  let maskedValue = "";

  for (let i = 0; i < value.length; i++) {
    maskedValue +=
      i > value.length - (numberOfPlacesOmitted ?? 4) - 1
        ? value.charAt(i)
        : "*";
  }

  return maskedValue;
}

import ConfigurationFileContents from "@/interfaces/Configuration/ConfigurationFileContents";

const SET_CONFIGURATION_CONTENTS = "SET_CONFIGURATION_CONTENTS";

export interface ConfigurationState {
  configurationContents: ConfigurationFileContents;
}
export default {
  namespaced: true as const,
  state: {
    configurationContents: {},
  } as ConfigurationState,
  getters: {
    configurationContents: (state: ConfigurationState) =>
      state.configurationContents,
  },
  mutations: {
    [SET_CONFIGURATION_CONTENTS](
      state: ConfigurationState,
      newConfigurationContents: ConfigurationFileContents
    ) {
      state.configurationContents = { ...newConfigurationContents };
    },
  },
  actions: {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    setConfigurationContents(context: any, payload: ConfigurationFileContents) {
      context.commit(SET_CONFIGURATION_CONTENTS, payload);
    },
  },
};

export function buildAddress(
  address1: string,
  address2: string,
  city: string,
  state: string,
  zip: string,
  country: string,
  lineSpacer = "\n"
): string {
  let address = "";

  if (address1) {
    if (address) address += lineSpacer;
    address += address1;
  }

  if (address2) {
    if (address) address += lineSpacer;
    address += address2;
  }

  if (city || state || zip) {
    if (address) address += lineSpacer;
    address += city + ", " + state + " " + zip;
  }

  if (country) {
    if (address) address += lineSpacer;
    address += country;
  }

  return address;
}


import { Mixins, Component, Prop } from "vue-property-decorator";
import WebLoginAccount from "@/interfaces/WebLogin/WebLoginAccount";
import store from "@/store";
import EmployeeCustomerAccess from "@/enumerations/Security/EmployeeCustomerAccess";
import SecuritySettingsMixin from "@/mixins/Security/SecuritySettingsMixin";
import { keywordMatchesSearchText } from "@/helpers/Search/SearchHelpers";

@Component
export default class SelectAccountCard extends Mixins(SecuritySettingsMixin) {
  @Prop({ required: false, default: [] })
  accounts!: WebLoginAccount[];

  searchText = "";
  sortBy = "name";

  get forceSelectAccount(): boolean {
    return !store.getters.webLoginModule.selectedAccount;
  }

  get canSearch(): boolean {
    return (
      store.getters.webLoginModule.webLogin.isEmployee &&
      store.getters.webLoginModule.webLogin.employeeCustomerAccess ===
        EmployeeCustomerAccess.all
    );
  }

  get managerName(): string {
    return store.getters.webLoginModule.webLogin.employeeManagerName;
  }

  get filteredAccounts(): WebLoginAccount[] {
    return (
      !this.searchText
        ? this.accounts
        : this.accounts.filter(
            (x) =>
              keywordMatchesSearchText(x.name, this.searchText) ||
              keywordMatchesSearchText(x.id, this.searchText)
          )
    ).sort((a, b) =>
      this.sortBy === "name"
        ? a.name.localeCompare(b.name)
        : a.id.localeCompare(b.id)
    );
  }

  cancelClicked(): void {
    this.$emit("onCancel");
  }

  returnClicked(): void {
    this.$emit("onReturn");
  }

  addAccount(): void {
    this.$emit("addAccount");
  }

  selectAccount(account: WebLoginAccount) {
    this.$emit("onSelectAccount", account);
  }

  selectManagerAccount() {
    this.$emit("onSelectAccount", {
      name: this.managerName,
    } as WebLoginAccount);
  }

  viewAccount(account: WebLoginAccount) {
    this.$emit("onViewAccount", account);
  }
}

import YesNoSecurity from "@/enumerations/Security/YesNoSecurity";
import SecuritySettingsBase from "./SecuritySettingsBase";

export default class ContractsSecuritySettings extends SecuritySettingsBase {
  private static instance: ContractsSecuritySettings;

  private constructor() {
    super();
  }

  public static getInstance(): ContractsSecuritySettings {
    if (!ContractsSecuritySettings.instance) {
      ContractsSecuritySettings.instance = new ContractsSecuritySettings();
    }

    return ContractsSecuritySettings.instance;
  }

  private get showContracts(): boolean {
    return (
      !this.isManagerAccountSelected &&
      this.checkUserProfileBasedSecurity(
        this.userProfileBasedSecurity.contracts,
        YesNoSecurity.yes
      )
    );
  }

  public get showContractsMenu(): boolean {
    return (
      this.showContracts &&
      (this.showViewBookingsPage || this.showViewPrepaidsPage)
    );
  }

  public get showViewBookingsPage(): boolean {
    return (
      this.parameters.showBookings &&
      this.showContracts &&
      this.checkUserProfileBasedSecurity(
        this.userProfileBasedSecurity.viewBookings,
        YesNoSecurity.yes
      )
    );
  }

  public get showViewPrepaidsPage(): boolean {
    return (
      this.parameters.showPrepaids &&
      this.showContracts &&
      this.checkUserProfileBasedSecurity(
        this.userProfileBasedSecurity.viewPrepaids,
        YesNoSecurity.yes
      )
    );
  }
}

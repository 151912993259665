import { RouteConfig } from "vue-router";
import AccountSecuritySettings from "@/helpers/Security/AccountSecuritySettings";
const ViewAddresses = () => import("@/views/Account/ViewAddresses.vue");
const ViewBuyers = () => import("@/views/Account/ViewBuyers.vue");
const ViewLogins = () => import("@/views/Account/ViewLogins.vue");
const ViewProfile = () => import("@/views/Account/ViewProfile.vue");
const ViewSummary = () => import("@/views/Account/ViewSummary.vue");

const accountSecuritySettings = AccountSecuritySettings.getInstance();

const accountMenuRoutes: RouteConfig[] = [
  {
    path: "/summary/view",
    name: "view-summary",
    component: ViewSummary,
    meta: {
      displayName: "Account Summary",
    },
    beforeEnter: (_to, _from, next) =>
      accountSecuritySettings.showAccountSummaryPage ? next() : next("/"),
  },
  {
    path: "/addresses/view",
    name: "view-addresses",
    component: ViewAddresses,
    meta: {
      displayName: "Address Book",
    },
    beforeEnter: (_to, _from, next) =>
      accountSecuritySettings.showAddressesPage ? next() : next("/"),
  },
  {
    path: "/profile/view",
    name: "view-profile",
    component: ViewProfile,
    meta: {
      displayName: "Profile",
    },
    beforeEnter: (_to, _from, next) =>
      accountSecuritySettings.showProfilePage ? next() : next("/"),
  },
  {
    path: "/account/buyers",
    name: "view-buyers",
    component: ViewBuyers,
    meta: {
      displayName: "Buyers",
    },
    beforeEnter: (_to, _from, next) =>
      accountSecuritySettings.showBuyersPage ? next() : next("/"),
  },
  {
    path: "/logins/view",
    name: "view-logins",
    component: ViewLogins,
    meta: {
      displayName: "Login History",
    },
    beforeEnter: (_to, _from, next) =>
      accountSecuritySettings.showLoginHistoryPage ? next() : next("/"),
  },
];

export default accountMenuRoutes;


import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  createWebLogin,
  verifyCustomerByReceipt,
} from "@/repositories/Web/WebLoginRepository";
import { addAccount } from "@/repositories/Web/WebMapRepository";
import WebLoginAccount from "@/interfaces/WebLogin/WebLoginAccount";
import { emailExpression } from "@/helpers/Validation/ValidationHelpers";
import UnprocessableEntityResponse from "@/interfaces/Repository/UnprocessableEntityResponse";

@Component
export default class CreateAccountForm extends Vue {
  isLoading = false;
  isValid = true;
  errorMessage = "";
  accountNumber = "";
  storeNumber = "";
  invoiceNumber = "";
  accountNumberErrors = [] as string[];
  storeNumberErrors = [] as string[];
  invoiceNumberErrors = [] as string[];
  username = "";
  displayName = "";
  emailAddress = "";
  usernameErrors = [] as string[];
  displayNameErrors = [] as string[];
  emailAddressErrors = [] as string[];
  layer = "lookup";
  emailRegEx = emailExpression;

  @Prop({ default: false })
  isAddingAccountToWebLogin!: boolean;

  @Prop()
  onChanged!: boolean;

  @Prop({ default: "" })
  title!: string;

  get buttonTitle(): string {
    return (
      (this.isAddingAccountToWebLogin ? "Add " : "Search For") + " Account"
    );
  }

  resetErrors(type = ""): void {
    if (type === "" || type === "accountNumber") {
      this.accountNumberErrors = [] as string[];
    }
    if (type === "" || type === "invoiceNumber") {
      this.invoiceNumberErrors = [] as string[];
    }
    if (type === "" || type === "storeNumber") {
      this.storeNumberErrors = [] as string[];
    }
    if (type === "" || type === "username") {
      this.usernameErrors = [] as string[];
    }
    if (type === "" || type === "displayName") {
      this.displayNameErrors = [] as string[];
    }
    if (type === "" || type === "emailAddress") {
      this.emailAddressErrors = [] as string[];
    }

    this.errorMessage = "";
  }

  private async searchAndAdd(): Promise<void> {
    this.resetErrors();
    const form = this.$refs.form as HTMLFormElement;
    form?.validate();

    if (!this.isValid) return;
    this.isLoading = true;
    const response = await verifyCustomerByReceipt(
      this.accountNumber,
      this.storeNumber,
      this.invoiceNumber
    );

    if (response.status === 422) {
      const errors = response.data as UnprocessableEntityResponse[];
      this.accountNumberErrors = errors
        .filter((x) => x.property === "accountNumber")
        .map((x) => x.message);
      this.storeNumberErrors = errors
        .filter((x) => x.property === "storeNumber")
        .map((x) => x.message);
      this.invoiceNumberErrors = errors
        .filter((x) => x.property === "invoiceNumber")
        .map((x) => x.message);
      this.errorMessage = errors
        .filter((x) => x.property === "")
        .map((x) => x.message)[0];
    } else if (!this.isAddingAccountToWebLogin) {
      //creating new account
      const selectedAccount = this.accountNumber;
      const selectedStore = this.storeNumber;
      const selectedInvoice = this.invoiceNumber;
      form?.reset();
      this.layer = "create";
      this.accountNumber = selectedAccount;
      this.storeNumber = selectedStore;
      this.invoiceNumber = selectedInvoice;
    } else {
      await this.addAccountToWebLogin(form);
    }

    this.isLoading = false;
  }

  private async addAccountToWebLogin(form: HTMLFormElement): Promise<void> {
    this.isLoading = true;
    const response = await addAccount(
      this.storeNumber,
      this.accountNumber,
      this.invoiceNumber
    );

    if (response.status === 422) {
      const errors = response.data as UnprocessableEntityResponse[];
      this.accountNumberErrors = errors
        .filter((x) => x.property === "AccountId")
        .map((x) => x.message);
    } else {
      const account = response.data as unknown as WebLoginAccount;
      form?.reset();
      this.$emit("onAdded", account);
    }
    this.isLoading = false;
  }

  private async createWebAccount(): Promise<void> {
    this.resetErrors();
    const form = this.$refs.form as HTMLFormElement;
    form?.validate();

    if (!this.isValid) return;
    this.isLoading = true;
    const response = await createWebLogin(
      this.username,
      this.displayName,
      this.emailAddress,
      this.storeNumber,
      this.accountNumber,
      this.invoiceNumber
    );

    if (response.status === 422) {
      const errors = response.data as UnprocessableEntityResponse[];
      this.usernameErrors = errors
        .filter((x) => x.property === "Username")
        .map((x) => x.message);
      this.displayNameErrors = errors
        .filter((x) => x.property === "DisplayName")
        .map((x) => x.message);
      this.emailAddressErrors = errors
        .filter((x) => x.property === "Email")
        .map((x) => x.message);
      this.errorMessage = errors
        .filter((x) => x.property === "")
        .map((x) => x.message)[0];
    } else {
      this.$emit("onCreated");
    }

    this.isLoading = false;
  }

  @Watch("onChanged")
  reset(): void {
    const form = this.$refs.form as HTMLFormElement;
    form?.reset();
    this.layer = "lookup";
  }
}

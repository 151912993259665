
import { Mixins, Component } from "vue-property-decorator";
import NavigationDrawerListGroupAccount from "./NavigationDrawerListGroupAccount.vue";
import NavigationDrawerListGroupOrders from "./NavigationDrawerListGroupOrders.vue";
import NavigationDrawerListGroupPurchases from "./NavigationDrawerListGroupPurchases.vue";
import NavigationDrawerListGroupCommodity from "./NavigationDrawerListGroupCommodity.vue";
import NavigationDrawerListGroupContracts from "./NavigationDrawerListGroupContracts.vue";
import NavigationDrawerListGroupPayments from "./NavigationDrawerListGroupPayments.vue";
import NavigationDrawerManager from "./NavigationDrawerManager.vue";
import store from "@/store";
import ParameterSettings from "@/interfaces/Parameters/ParameterSettings";
import SecuritySettingsMixin from "@/mixins/Security/SecuritySettingsMixin";

@Component({
  components: {
    NavigationDrawerListGroupAccount,
    NavigationDrawerListGroupOrders,
    NavigationDrawerListGroupPurchases,
    NavigationDrawerListGroupCommodity,
    NavigationDrawerListGroupContracts,
    NavigationDrawerListGroupPayments,
    NavigationDrawerManager,
  },
})
export default class NavigationDrawer extends Mixins(SecuritySettingsMixin) {
  showDrawer = false;
  isInstalledAsPwa = false;

  created() {
    this.isInstalledAsPwa = window.matchMedia(
      "(display-mode: standalone)"
    ).matches;
  }

  mounted() {
    this.showDrawer = this.$vmq.lg;
  }

  public toggleDrawer(): void {
    this.showDrawer = !this.showDrawer;
  }

  get parameterSettings(): ParameterSettings {
    return store.getters.parametersModule.parameterSettings;
  }

  get parametersLoading(): boolean {
    return store.getters.parametersModule.isLoading;
  }

  get fieldAlyticsDisplayName(): string {
    return store.getters.parametersModule.parameterSettings.fieldAlyticsDisplayName.trim() ===
      ""
      ? "FieldAlytics"
      : store.getters.parametersModule.parameterSettings
          .fieldAlyticsDisplayName;
  }
}

enum EmployeeCustomerAccess {
  /** All customers */
  all = 0,
  /** All customers associated with the selected field rep */
  fieldRep = 1,
  /** All customers for all field reps associated with the selected user profile */
  userProfile = 2,
}

export default EmployeeCustomerAccess;

import { Vue, Component } from "vue-property-decorator";

@Component
export default class UpdaterMixin extends Vue {
  serviceWorkerRegistration!: ServiceWorkerRegistration;
  updateExists = false;
  isRefreshingForUpdate = false;

  created() {
    document.addEventListener(
      "serviceWorkerUpdated",
      this.promptUpdateAvailable,
      { once: true }
    );
    navigator.serviceWorker.addEventListener(
      "controllerchange",
      this.serviceWorkerChanged
    );
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  promptUpdateAvailable(event: any): void {
    this.serviceWorkerRegistration = event.detail;
    this.updateExists = true;
  }

  refreshApp(): void {
    this.updateExists = false;
    if (
      this.serviceWorkerRegistration !== null &&
      this.serviceWorkerRegistration.waiting
    ) {
      // Send message to service worker to skip the waiting and activate the new service worker
      this.serviceWorkerRegistration.waiting.postMessage({
        type: "SKIP_WAITING",
      });
    }
  }

  serviceWorkerChanged(): void {
    if (this.isRefreshingForUpdate) return;
    this.isRefreshingForUpdate = true;
    window.location.reload();
  }
}


import { Component, Ref, Watch, Mixins } from "vue-property-decorator";
import { Route } from "vue-router";
import NavigationDrawer from "@/components/App/NavigationDrawer/NavigationDrawer.vue";
import AccountSelectionModal from "@/components/WebLogin/AccountSelection/AccountSelectionModal.vue";
import Notification from "@/interfaces/Notifications/Notification";
import { getAccountNameInitials } from "@/helpers/WebLogin/WebLoginHelpers";
import store from "@/store";
import UpdaterMixin from "@/mixins/App/UpdaterMixin";
import AccountPasswordResetForm from "@/components/Account/AccountPasswordResetForm.vue";
import CreateAccountForm from "@/components/Account/CreateAccountForm.vue";
import WebLoginAccount from "@/interfaces/WebLogin/WebLoginAccount";
import { setAccountLastLogin } from "@/repositories/Web/WebLoginRepository";
import SecuritySettingsMixin from "./mixins/Security/SecuritySettingsMixin";

@Component({
  components: {
    NavigationDrawer,
    AccountSelectionModal,
    AccountPasswordResetForm,
    CreateAccountForm,
  },
})
export default class App extends Mixins(UpdaterMixin, SecuritySettingsMixin) {
  @Ref()
  navigationDrawer!: NavigationDrawer;
  toolBarTitle = "Welcome";
  showAccountSelectionDialog = false;
  notificationTimeout = 6000;
  showAppBars = false;
  showChangePasswordDialog = false;
  changePasswordDialogKey = 0;
  showAddAccountDialog = false;
  addAccountDialogKey = 0;

  get selectedAccount() {
    return store.getters.webLoginModule.selectedAccount;
  }

  @Watch("$route", { immediate: true, deep: true })
  routeChanged(newRoute: Route) {
    this.toolBarTitle = newRoute.meta?.displayName;
    this.showAppBars = !(
      !this.$router.currentRoute.name ||
      this.$router.currentRoute.name === "login" ||
      this.$router.currentRoute.name === "createAccount" ||
      this.$router.currentRoute.name === "resetPassword" ||
      this.$router.currentRoute.name === "install-app-how-to"
    );
    if (
      store.getters.webLoginModule.selectedAccount === undefined &&
      !(
        !this.$router.currentRoute.name ||
        this.$router.currentRoute.name === "login" ||
        this.$router.currentRoute.name === "createAccount" ||
        this.$router.currentRoute.name === "resetPassword" ||
        this.$router.currentRoute.name === "install-app-how-to"
      )
    ) {
      if (this.generalSecuritySettings.manager) {
        store.dispatch.webLoginModule.setSelectedAccount({
          name: store.getters.webLoginModule.webLogin.employeeManagerName,
          canSwitchAccounts: true,
        } as WebLoginAccount);
      } else {
        this.showAccountSelectionDialog = true;
      }
    }
  }

  toggleDrawerClick(): void {
    this.navigationDrawer.toggleDrawer();
  }

  dismissAlert() {
    store.dispatch.alertsModule.setAlert(undefined);
  }

  logOut(): void {
    store.dispatch.webLoginModule.clearState();
    this.$router.push("/login");
  }

  private closeChangePasswordDialog() {
    this.showChangePasswordDialog = false;
    this.changePasswordDialogKey++;
  }

  async selectAccount(account: WebLoginAccount): Promise<void> {
    account.canSwitchAccounts = true;
    store.dispatch.webLoginModule.setSelectedAccount(account);
    if (account.isAccountLinkedToWebLogin) {
      await setAccountLastLogin(account.id);
    }
    this.closeAddAccountDialog();
  }

  private closeAddAccountDialog() {
    this.showAddAccountDialog = false;
    this.addAccountDialogKey++;
  }

  get accountInitials() {
    return getAccountNameInitials(
      store.getters.webLoginModule.selectedAccount?.name ?? ""
    );
  }

  get accountName() {
    return store.getters.webLoginModule.selectedAccount?.name;
  }

  get canSwitchAccounts() {
    return store.getters.webLoginModule.selectedAccount?.canSwitchAccounts;
  }

  get notification() {
    return (
      store.getters.notificationsModule.notification ?? ({} as Notification)
    );
  }

  get showNotification() {
    return store.getters.notificationsModule.notification !== undefined;
  }
  set showNotification(show: boolean) {
    if (!show) {
      store.dispatch.notificationsModule.setNotification(undefined);
    }
  }

  get alert() {
    return store.getters.alertsModule.alert;
  }

  private get showAppLoadingIndicator(): boolean {
    return store.getters.parametersModule.isLoading;
  }

  private get versionNumber(): string {
    return process.env.VUE_APP_VERSION_NUMBER;
  }
}
